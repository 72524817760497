<div [formGroup]="formGroup" class="filters-and-choice">
  <ng-container *ngFor="let control of formGroup.controls | keyvalue">
    <!-- Always active filters -->
    <div *ngIf="filtersMap.get(control.key).options?.alwaysActive">
      <div *ngIf="filtersMap.get(control.key).type === FILTER_TYPE.STRING || filtersMap.get(control.key).type === FILTER_TYPE.GUID">
        <mat-form-field class="filters-size">
          <mat-label>
            {{
            filtersMap.get(control.key).options?.placeHolder
              ? filtersMap.get(control.key).options?.placeHolder
              : filtersMap.get(control.key).filterName
            }}
          </mat-label>
          <mat-icon class="icon-search" matPrefix></mat-icon>

          <input [formControlName]="control.key" matInput />
        </mat-form-field>
      </div>
      <div *ngIf="filtersMap.get(control.key).type === FILTER_TYPE.CHOICE">
        <mat-form-field class="small">
          <mat-label>
            {{
            filtersMap.get(control.key).options?.placeHolder
              ? filtersMap.get(control.key).options?.placeHolder
              : filtersMap.get(control.key).filterName
            }}
          </mat-label>
          <mat-icon *ngIf="selectTag.panelOpen"
                    [fontIcon]="'icon-chevron-up'"
                    [fontSet]="'icomoon'"
                    matSuffix></mat-icon>
          <mat-icon *ngIf="!selectTag.panelOpen"
                    [fontIcon]="'icon-chevron-down'"
                    [fontSet]="'icomoon'"
                    matSuffix></mat-icon>

          <mat-select #selectTag [formControlName]="control.key" multiple>
            <mat-option *ngFor="let option of filtersMap.get(control.key).options?.filterChoices"
                        [value]="option.value">
              {{ option | json }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </ng-container>

  <!-- Selected filters -->
  <ng-container *ngFor="let control of formGroup.controls | keyvalue">
    <div *ngIf="!control.value.disabled && !filtersMap.get(control.key).options?.alwaysActive">
      <div *ngIf="filtersMap.get(control.key).type === FILTER_TYPE.STRING || filtersMap.get(control.key).type === FILTER_TYPE.GUID">
        <mat-form-field class="filters-size">
          <mat-label>
            {{
            filtersMap.get(control.key).options?.placeHolder
              ? filtersMap.get(control.key).options?.placeHolder
              : filtersMap.get(control.key).filterName
            }}
          </mat-label>
          <mat-icon class="icon-search" matPrefix></mat-icon>

          <input [formControlName]="control.key" matInput />
        </mat-form-field>
      </div>

      <div *ngIf="filtersMap.get(control.key).type === FILTER_TYPE.CHOICE">
        <mat-form-field class="filters-size">
          <mat-label>
            {{
            filtersMap.get(control.key).options?.placeHolder
              ? filtersMap.get(control.key).options?.placeHolder
              : filtersMap.get(control.key).filterName
            }}
          </mat-label>
          <mat-icon *ngIf="selectTag.panelOpen"
                    [fontIcon]="'icon-chevron-up'"
                    [fontSet]="'icomoon'"
                    matSuffix></mat-icon>
          <mat-icon *ngIf="!selectTag.panelOpen"
                    [fontIcon]="'icon-chevron-down'"
                    [fontSet]="'icomoon'"
                    matSuffix></mat-icon>
          <mat-select #selectTag [formControlName]="control.key" multiple>
            <mat-option *ngFor="let option of filtersMap.get(control.key).options?.filterChoices" [value]="option">
              {{
              option.label
              }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </ng-container>

  <!-- FILTER SELECT BUTTON -->
  <div *ngIf="nonMandatoryFilters.length > 0" class="select-button">
    <button (click)="filterButton.open()" class="secondary-button small" mat-button>
      <mat-icon [fontIcon]="'icon-filter-stroke'" [fontSet]="'icomoon'" matPrefix></mat-icon>
      <span i18n="Button label - Tells the user that this button shows the filters@@f-open-filters">Filter</span>
    </button>
    <mat-select #filterButton [formControl]="chosenFilters" multiple>
      <mat-option (onSelectionChange)="toggleFilter($event, $event.source.value.toString())"
                  *ngFor="let filter of nonMandatoryFilters"
                  [value]="filter.columnReference">
        {{ filter.filterName }}
      </mat-option>
    </mat-select>
  </div>
</div>
