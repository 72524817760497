<div class="confirmation-dialog-wrapper">
  <header>
    <h2>
      <span [ngClass]="data.titleIcon"></span><span>{{ data.titleText }}</span>
    </h2>
  </header>

  <p>{{ data.descriptionText }}</p>

  <div class="cd-buttons">
    <button (click)="reject()" class="secondary-button" mat-button>{{ data.rejectText }}</button>
    <button (click)="resolve()" class="primary-button" mat-button>{{ data.resolveText }}</button>
  </div>
</div>
