<div
  #menuTrigger="matMenuTrigger"
  [class.isOpen]="menuTrigger.menuOpen"
  [matMenuTriggerFor]="menu"
  class="user-profile">
  <button class="menu-button">
    <span class="initials">{{ initials }}</span>
  </button>
</div>
<mat-menu #menu="matMenu" class="user-profile-dropdown-menu">
  <div class="user-info">
    <span class="name">{{ name }}</span>
    <br />
    <span class="mail">{{ mail }}</span>
  </div>
  <ng-container *ngFor="let option of options; index as i">
    <button (click)="action(option.action)" mat-menu-item>
      <mat-icon *ngIf="option.icon" fontIcon="{{ option.icon }}" fontSet="icomoon"></mat-icon>
      <span>{{ option.label }}</span>
    </button>
    <hr *ngIf="options.length > i + 1" />
  </ng-container>
</mat-menu>
