<div class="header-content">
  <app-redirection-header (exit)="redirectToHome()" [currentPageName]="accountTitle"></app-redirection-header>
</div>
<div *ngIf="isLoading" class="spinner-wrapper">
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>
<div *ngIf="!isLoading">
  <form *ngIf="form" (ngSubmit)="submit()" [formGroup]="form" class="account-wrapper">
    <mat-form-field>
      <mat-label i18n="Input placeholder - User can type an profil firstname@@a-profil-firstname-dialog">
        Firstname:
      </mat-label>
      <input [maxlength]="256" formControlName="firstName" matInput />
  
    </mat-form-field>
    <mat-form-field>
      <mat-label i18n="Input placeholder - User can type an profil lastname@@a-profil-lastname-dialog">
        lastname:
      </mat-label>
      <input [maxlength]="256" formControlName="lastName" matInput />
    </mat-form-field>
    <mat-form-field>
      <mat-label i18n="Input placeholder - User can type an profil name@@asnc-displayName">
        Display Name:
      </mat-label>
      <input [maxlength]="256" formControlName="displayName" matInput />
      <mat-error *ngIf="form.get('displayName').hasError('required')"
              i18n="
        Error text localized under invalid input - Tells the user that this input is
        mandatory@@input-required">
        Input required
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label i18n="Input placeholder - User can type an profil jibtitle@@a-profil-jobtitle-dialog">
        jobTitle:
      </mat-label>
      <input [maxlength]="256" formControlName="jobTitle" matInput />
    </mat-form-field>
    <app-international-phone-input formControlName="phoneNumber" [inputWidth]="'230px'" ></app-international-phone-input>
    <div class="readonly-fields">
      <span class="readonly-labels">Email: </span><span class="readonly-value">{{ userProperties.email }}</span>
    </div>

    <div class="readonly-fields">
      <span class="readonly-labels">Main organization: </span><span class="readonly-value">{{ userProperties.mainOrgDisplayName }}</span>
      <a (click)="sendMail()">
        Ask to change main organization
      </a>
    </div>
    <div class="apply-button">
      <button *appAccessControl="[ACCESS_CONTROL.ACCOUNT_PUT]"
              [disabled]="!form.valid"
              class="primary-button custom-button"
              i18n="Button label - User can apply modifications with this button@@ogud-apply"
              mat-button
              type="submit">
        Apply
      </button>
    </div>
  </form>
  <div class="my-organizations">
    <h3 i18n="text - Organization and workspace associated with the user@@a-account-orgws-dialog">Organizations and workspaces you belong to: </h3>
    <div *ngFor="let org of userOrgPropertires; let index = index">
      <div [id]="index" #orgRef class="org-content">
        <button (click)="openList(index)" class="large-icon" [ngClass] ="{'icon-chevron-down  small-icon' : orgRef.id == showId, 'icon-chevron-right' : orgRef.id != showId}"
                mat-button>
        </button>

        <span>{{org.displayName}}</span>
      </div>
      <div [id]="index" #wsRef *ngFor="let ws of org.workspaces" class="org-content ws-content">
        <li *ngIf="wsRef.id === showId">{{ws.displayName}}</li>
      </div>
    </div>
  </div>
</div>
