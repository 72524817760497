import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-layout-wrapper',
  templateUrl: './layout-wrapper.component.html',
  styleUrls: ['./layout-wrapper.component.scss'],
})
export class LayoutWrapperComponent implements OnInit {
  showBreadCrumb: boolean;
  showNavBar: boolean = true;

  constructor(private readonly router: Router) {}

  ngOnInit(): void {
    // The router events only returns the next value submitted (after the subscribe) and not the current value during the subscribe.
    this.showBreadCrumb = this.canShowBreadCrumbs();
    this.showNavBar = this.canShowNavBar();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.showNavBar = this.canShowNavBar();
        this.showBreadCrumb = this.canShowBreadCrumbs();
      }
    });
  }

  canShowNavBar(): boolean {
    const currentRoute = this.router.url;
    return (
      currentRoute !== '/admin-settings' &&
      currentRoute !== '/admin-settings/organizations' &&
      currentRoute !== '/admin-settings/applications' &&
      currentRoute !== '/admin-settings/workspaces' &&
      currentRoute !== '/account' &&
      currentRoute !== '/admin-settings/users' &&
      currentRoute !== '/admin-settings/contacts' &&
      currentRoute !== '/admin-settings/secrets' &&
      currentRoute !== '/admin-settings/domains'
    );
  }

  canShowBreadCrumbs(): boolean {
    const currentRoute = this.router.url;
    return (
      currentRoute !== '/page-not-found' &&
      currentRoute !== '/unauthorized' &&
      currentRoute !== '/faq' &&
      currentRoute !== '/account' &&
      currentRoute !== '/admin-settings' &&
      currentRoute !== '/admin-settings/organizations' &&
      currentRoute !== '/admin-settings/applications' &&
      currentRoute !== '/admin-settings/workspaces' &&
      currentRoute !== '/admin-settings/users' &&
      currentRoute !== '/admin-settings/contacts' &&
      currentRoute !== '/admin-settings/secrets' &&
      currentRoute !== '/admin-settings/domains'
    );
  }
}
