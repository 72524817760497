import { Pipe, PipeTransform } from '@angular/core';
import { USER_STATUS } from '../models/enums/USER_STATUS';

@Pipe({
  name: 'userStatus',
})
export class UserStatusPipe implements PipeTransform {
  transform(userStatus: USER_STATUS | string): string {
    switch (userStatus) {
      case USER_STATUS.ACTIVE:
        return $localize`:Text localized in user infos (table, modale, etc.) - Displays the user account status (active, inactive, invited)@@user-status-active:Active`;
      case USER_STATUS.INACTIVE:
        return $localize`:Text localized in user infos (table, modale, etc.) - Displays the user account status (active, inactive, invited)@@user-status-inactive:Inactive`;
      case USER_STATUS.INVITED:
        return $localize`:Text localized in user infos (table, modale, etc.) - Displays the user account status (active, inactive, invited)@@user-status-invited:Invited`;
      default:
        return userStatus;
    }
  }
}
