<nav class="navbar">
  <div class="toggle-navbar-extension-wrapper">
    <button (click)="toggleNavbar()" class="toggle-navbar-extension">
      <span [ngClass]="isExtensionVisible ? 'icon-double-chevron-left' : 'icon-double-chevron-right'"></span>
    </button>
  </div>

  <!--<div
    (click)="toggleNavbar()"
    *ngIf="!isExtensionVisible"
    [matTooltip]="organizationFormControl.value.label"
    class="organization-initials"
    matTooltipPosition="after">
    <span class="initials">{{ organizationFormControl.value.label.charAt(0) }}</span>
  </div>-->

  <div *ngFor="let item of items" class="navbar-link-wrapper">
    <a
      (isActiveChange)="item.isExpanded = $event"
      [routerLink]="'/' + item.routerLink"
      class="navbar-link"
      routerLinkActive="active">
      <span [class]="item.icon" class="navbar-icon"></span>
      <span [class.visible]="isExtensionVisible" class="navbar-extension-text">{{ item.label }}</span>
    </a>
  </div>
</nav>
