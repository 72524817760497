import { Pipe, PipeTransform } from '@angular/core';
import { CONTACT_TYPE } from '../models/enums/CONTACT_TYPE';

@Pipe({
  name: 'contactTypePipe',
})
export class ContactTypePipe implements PipeTransform {
  transform(type: CONTACT_TYPE | string): string {
    switch (type) {
      case CONTACT_TYPE.HARDIS:
        return $localize`:Text localized in contact info. - Displays the contact type@@contact-type-hardis:Customer Contact`;
      case CONTACT_TYPE.CLIENT:
        return $localize`:Text localized in contact info. - Displays the contact typee@@contact-type-client:HG Contact`;
      case CONTACT_TYPE.CLIENT_PRINCIPAL:
        return $localize`:Text localized in contact info. - Displays the contact type@@contact-type-principal:Main Organization Contact`;
      case CONTACT_TYPE.GESTIONNAIRE_COMPTE:
        return $localize`:Text localized in contact info. - Displays the contact type@@contact-type-gestionnaire-compte:Account Manager`;
      default:
        return type;
    }
  }
}
