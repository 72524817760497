import { Injectable } from '@angular/core';
import { ACCESS_CONTROL } from '../models/enums/ACCESS_CONTROL';
import { catchError, combineLatestWith, map, Observable, of, take } from 'rxjs';
import { UserAccountService } from './data/user-account.service';
import { UserAccountDto } from '../models/dto/UserAccountDto';
import { OrganizationService } from './data/organization.service';
import { OrganizationLightDto } from '../models/dto/OrganizationLightDto';
import { UserApiService } from './api/user-api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private userAcount: UserAccountDto;
  constructor(
    private readonly userAccountService: UserAccountService,
    private readonly organizationService: OrganizationService
  ) { }

  public verifyAccess(acl: ACCESS_CONTROL[] = []): Observable<boolean> {
    // Si la liste d'ACL est vide, on accorde l'accès directement
    if (acl.length === 0) {
      return of(true);
    }

    // Utilisation de l'observable `userAccountReplay$` pour vérifier les permissions
    return this.userAccountService.userAccountReplay$.pipe(
      take(1), // Ne prendre qu'une seule émission de l'Observable
      map((userAccount: UserAccountDto) => {
        // Vérifie que chaque élément de l'ACL est présent dans les permissions de l'utilisateur
        return acl.every(ac => userAccount.acl.includes(ac));
      }),
      catchError(() => of(false)) // En cas d'erreur, l'accès est refusé
    );
  }
}
