import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { OrganizationLightDto } from '../../models/dto/OrganizationLightDto';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  private _selectedOrganizationReplay: ReplaySubject<OrganizationLightDto> = new ReplaySubject<OrganizationLightDto>();
  public selectedOrganizationReplay$: Observable<OrganizationLightDto> =
    this._selectedOrganizationReplay.asObservable();

  private _selectedOrganization: BehaviorSubject<OrganizationLightDto> = new BehaviorSubject<OrganizationLightDto>(
    null
  );
  public selectedOrganization$: Observable<OrganizationLightDto> = this._selectedOrganization.asObservable();

  public get selectedOrganization(): OrganizationLightDto {
    return this._selectedOrganization.getValue();
  }

  public set selectedOrganization(organization: OrganizationLightDto) {
    this._selectedOrganization.next(organization);
    this._selectedOrganizationReplay.next(organization);
  }
}
