<mat-form-field class='sd-dropdown'>
  <mat-label>Organization</mat-label>
  <mat-select
    #sdSelectRef
    (openedChange)='onOpenedChange($event, sdInput)'
    [formControl]='selectFormControl'
    [panelClass]="'sd-panel'">
    <div class='sd-panel-search'>
      <mat-form-field class='white'>
        <mat-icon fontIcon='icon-search' fontSet='icomoon' matPrefix></mat-icon>
        <mat-label
          i18n='
            Label localized in a dropdown that has a research function - tells the user to search by typing in this
            field@@sd-placeholder'
        >Search...
        </mat-label>
        <input #sdInput (input)='filter(sdInput.value)' matInput />
      </mat-form-field>
    </div>
    <mat-option *ngFor='let option of filteredOptions' [value]='option'>
      {{ option.label }}
    </mat-option>
  </mat-select>
  <mat-icon *ngIf='sdSelectRef.panelOpen' [fontIcon]="'icon-chevron-up'" [fontSet]="'icomoon'" matSuffix></mat-icon>
  <mat-icon *ngIf='!sdSelectRef.panelOpen' [fontIcon]="'icon-chevron-down'" [fontSet]="'icomoon'" matSuffix></mat-icon>
</mat-form-field>
