import { Component, Inject, isDevMode, LOCALE_ID } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { authCodeFlowConfig } from '../../auth-code-flow-config';
import { Router } from '@angular/router';
import { GlobalLoadingService } from './shared/services/global-loading.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private readonly router: Router,
    private readonly oauthService: OAuthService,
    readonly globalLoadingService: GlobalLoadingService,
    @Inject(LOCALE_ID) private readonly locale: string
  ) {
    this.configureCodeFlow();
  }

  /**
   * Configures the authentication code flow with specific rules (auth-code-flow-config.ts)
   * and calls necessary conf methods.
   *
   * To be used at startup
   */
  private configureCodeFlow() {
    this.oauthService.configure(authCodeFlowConfig);
    // In dev mode (serve), we don't have multi languages so only one redirect uri possible : origin
    if (environment.debug) {
      this.oauthService.redirectUri = window.location.origin + '/home';
    } else {
      this.oauthService.redirectUri = window.location.origin + '/' + this.locale + '/home';
    }

    this.oauthService.loadDiscoveryDocumentAndTryLogin().finally();
  }
}
