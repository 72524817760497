import { Component } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import { UserAccountService } from '../../../services/data/user-account.service';
import { AuthService } from '../../../services/auth.service';
import { ACCESS_CONTROL } from '../../../models/enums/ACCESS_CONTROL';

class UserProfileMenuItem {
  label: string;
  icon?: string;
  action?: () => void;
}

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent {
  options: UserProfileMenuItem[] = [
    /*
    {
      label: $localize`:Menu item localized in user profile menu (top right) - displays application information modal window@@up-menu-about:About`,
      icon: 'icon-info-circle-stroke',
    },
*/
    {
      label: $localize`:Menu item localized in user profile menu (top right) - logs out user from application@@up-menu-logout:Log out`,
      icon: 'icon-log-out',
      action: () => this.oauthService.logOut({ logout_hint: this.oauthService.getIdentityClaims()['login_hint'] }),
    },

  ];
  initials: string;
  name: string;
  mail: string;
  protected readonly ACCESS_CONTROL = ACCESS_CONTROL;

  constructor(
    private readonly oauthService: OAuthService,
    private readonly userAccountService: UserAccountService,
    private readonly router: Router,
    private readonly authService: AuthService
  ) {
    this.initials = this.userAccountService.userAccount.contact.displayName.substring(0, 2).toUpperCase();
    this.mail = this.userAccountService.userAccount.contact.email;
    this.name = this.userAccountService.userAccount.contact.displayName;
    this.authService.verifyAccess([ACCESS_CONTROL.FAQ_GET]).subscribe((hasAccess: boolean) => {
      if (hasAccess) {
        this.options.unshift({
          label: $localize`:Menu item localized in user profile menu (top right), acronym for Frequently Asked Questions - redirects to FAQ page@@up-menu-faq:FAQ`,
          icon: 'icon-question-circle-stroke',
          action: () => this.router.navigate(['/faq']),
        });
      }
    });
    this.options.unshift({
      label: $localize`:Menu item localized in user profile menu (top right),  redirects to account page@@up-menu-account:Account`,
      icon: 'icon-question-circle-stroke',
      action: () => this.router.navigate(['/account']),
    });
  }

  /**
   * Applies the defined menu action on menu item click.
   * @param action the function to call.
   */
  public action(action: () => void) {
    if (action !== null && action !== undefined) {
      action();
    }
  }
}
