import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-user-tooltip',
  templateUrl: './user-tooltip.component.html',
  styleUrls: ['./user-tooltip.component.scss'],
})
export class UserTooltipComponent {
  @Input() description: string;
  @Input() icon: string;
}
