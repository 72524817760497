import { FILTER_TYPE } from '../filters/FILTER_TYPE';
import { OPERAND } from '../filters/OPERAND';
import { FilledFilter } from '../filters/FilledFilter';
import { FilterChoice } from '../filters/FilterChoice';

export class FilterCommandDto {
  public Value: string;
  public Values: string[];

  constructor(public Name: string, public Type: FILTER_TYPE, public Operand: OPERAND) {}

  public static fromFilledFilters(filledFilters: FilledFilter[]): string {
    const filterCommands: FilterCommandDto[] = [];
    filledFilters.forEach((ff) => {
      let command: FilterCommandDto;
      switch (ff.type) {
        case FILTER_TYPE.CHOICE:
          command = new FilterCommandDto(ff.column, ff.type, OPERAND.IN);
          command.Values = (ff.value as FilterChoice[]).map((fc: FilterChoice) => fc.value);
          filterCommands.push(command);
          break;
        case FILTER_TYPE.STRING:
        default:
          command = new FilterCommandDto(ff.column, ff.type, OPERAND.CONTAINS);
          command.Value = ff.value.toString();
          filterCommands.push(command);
          break;
      }
    });
    return JSON.stringify(filterCommands);
  }
}
