import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { Breadcrumb } from '../../models/BreadCrumb';
import { OrganizationService } from '../../services/data/organization.service';
import { SharedService } from '../../services/data/shared.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs: Breadcrumb[] = [];
  organizationName: string = '';
  private test: string = '';


  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private sharedService: SharedService,
    private readonly organizationService: OrganizationService
  ) { }

  ngOnInit(): void {
    this.generateBreadcrumb();
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.generateBreadcrumb();
    });
  }

  public navigateTo(orgid: string, wsId: string, url: string) {
    //this.sharedService.organizationIdToEdit = orgid;
    //this.sharedService.workspaceIdToEdit = wsId;
    this.router.navigate([url], { state: { idOrg: orgid, idWs: wsId } });
  }

  generateBreadcrumb() {
    this.breadcrumbs = [];
    let currentRoute: ActivatedRoute | null = this.activatedRoute.root;
    let routePath: string = '';
    let route: Router;
    let key: string;
    while (currentRoute) {
      if (currentRoute.snapshot.url.length != 0) {
        var test = routePath.lastIndexOf('/')
        this.organizationName = routePath.substring(test + 1);

        for (let i = 0; i < currentRoute.snapshot.url.length; i++) {
          const label = currentRoute.snapshot.data['label']
            ? currentRoute.snapshot.data['label']
            : currentRoute.snapshot.url[i].path.charAt(0).toUpperCase() + currentRoute.snapshot.url[i].path.slice(1);
          routePath += '/' + currentRoute.snapshot.url[i].path;
          this.breadcrumbs.push({
            label: label,
            url: `${routePath}`,
            idOrg: history.state.idOrg,
            idWs: history.state.idWs
            /*param: { [`${key}`]: this.activatedRoute.snapshot.queryParams[key]}*/
          });
        }

      }
      currentRoute = currentRoute.firstChild;
    }
  }
}
