import { isDevMode, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutWrapperComponent } from './layout-wrapper/layout-wrapper.component';
import { TestComponentsComponent } from './test-components/test-components.component';
import { FaqComponent } from './shared/components/header/user-profile/faq/faq.component';
import { ACCESS_CONTROL } from './shared/models/enums/ACCESS_CONTROL';
import { ACCESS_CONTROL_GUARD } from './shared/services/ACCESS_CONTROL_GUARD';
import { AUTH_GUARD } from './shared/services/AUTH_GUARD';
import { DATA_RESOLUTION_GUARD } from './shared/services/DATA_RESOLUTION_GUARD';
import { AccountComponent } from './shared/components/header/user-profile/account/account.component';

const routes: Routes = [
  { path: 'login', loadChildren: () => import('./features/login/login.module').then((m) => m.LoginModule) },
  {
    path: '',
    component: LayoutWrapperComponent,
    canActivate: [AUTH_GUARD, DATA_RESOLUTION_GUARD],
    children: [
      {
        path: 'home',
        loadChildren: () => import('./features/home/home.module').then((m) => m.HomeModule),
        data: {
          label: $localize`:Menu item localized in main menu (left), text also used in the breadcrumb - redirects to home page@@nav-home:Home`,
        },
      },
      {
        path: 'faq',
        component: FaqComponent,
        canActivate: [ACCESS_CONTROL_GUARD],
        data: {
          label: $localize`:Menu item localized in user profile menu (top right), acronym for Frequently Asked Questions - redirects to FAQ page@@up-menu-faq:FAQ`,
          acl: [ACCESS_CONTROL.FAQ_GET],
        },
      },
      {
        path: 'account',
        component: AccountComponent,
        canActivate: [ACCESS_CONTROL_GUARD],
        data: {
          label: $localize`:Menu item localized in user profile menu (top right),  redirects to Profil page@@up-menu-profil:Profil`
        },
      },
      {
        path: 'organizations',
        loadChildren: () =>
          import('./features/organization-page/organization-page.module').then((m) => m.OrganizationPageModule),
        canActivate: [ACCESS_CONTROL_GUARD],
        data: {
          label: $localize`:Menu item localized in main menu (left), text also used in the breadcrumb - redirects to organization page@@nav-organization:Organizations`,
          acl: [ACCESS_CONTROL.ORGANIZATIONS_GET],
        },
      },
      {
        path: 'workspaces',
        loadChildren: () =>
          import('./features/workspaces/workspaces-page.module').then((m) => m.WorkspacesPageModule),
        canActivate: [ACCESS_CONTROL_GUARD],
        data: {
          label: $localize`:Menu item localized in main menu (left), text also used in the breadcrumb - redirects to organization page@@nav-workspace:Workspaces`,
          acl: [ACCESS_CONTROL.WORKSPACES_GET],
        },
      },
      {
        path: 'applications',
        loadChildren: () => import('./features/applications/applications.module').then((m) => m.ApplicationsModule),
        canActivate: [ACCESS_CONTROL_GUARD],
        data: {
          label: $localize`:Menu item localized in main menu (left), text also used in the breadcrumb - redirects to apps links page@@nav-applications:Applications`,
          acl: [ACCESS_CONTROL.APPLICATIONS_GET],
        },
      },
      {
        path: 'admin-settings',
        loadChildren: () =>
          import('./features/admin-settings/admin-settings.module').then((m) => m.AdminSettingsModule),
        canActivate: [ACCESS_CONTROL_GUARD],
      },
      {
        path: 'unauthorized',
        loadChildren: () => import('./features/unauthorized/unauthorized.module').then((m) => m.UnauthorizedModule),
      },
      {
        path: 'page-not-found',
        loadChildren: () => import('./features/page-not-found/page-not-found.module').then((m) => m.PageNotFoundModule),
      },
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: ':orgName',
        loadChildren: () =>
          import('./features/organization-page/all-organizations/specific-organization/specific-organization.module').then((m) => m.SpecificOrganisationModule),
        data: {
          acl: [ACCESS_CONTROL.ORGANIZATION_GET],
          orgId:':orgId'
        },
      },
      {
        path: ':orgName/:wsName',
        loadChildren: () =>
          import('./features/organization-page/all-organizations/specific-organization/organization-workspace/specific-workspace/specific-workspace.module').then((m) => m.SpecificWorkspaceModule),
        data: {
          acl: [ACCESS_CONTROL.WORKSPACE_GET],
          wsId: ':wsId'
        },
      },
      { path: '**', redirectTo: 'page-not-found', pathMatch: 'full' },
    ],
  },
];

if (isDevMode()) {
  routes.unshift({ path: 'TEST-COMPONENTS', component: TestComponentsComponent, canActivate: [AUTH_GUARD] });
}

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
