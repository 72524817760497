import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SearchDropdownOption } from '../../../models/SearchDropdownOption';

/**
 * Dropdown component which permits filtering. Features :
 * - dropdown toggle (visible, hidden) button, displaying the current selected option or a placeholder
 * - dropdown panel with search input which filters following displayed options
 *
 * Attributes :
 * - the `options` attribute is **required** and contains the list of available {@link SearchDropdownOption}
 * - the `selected` attribute is **optional** and contains a single {@link SearchDropdownOption} used as default value
 * (in case of reactive forms, will be overwritten by *formControl* value)
 */
@Component({
  selector: 'app-search-dropdown[options]',
  templateUrl: './search-dropdown.component.html',
  styleUrls: ['./search-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchDropdownComponent),
      multi: true,
    },
  ],
})
export class SearchDropdownComponent implements ControlValueAccessor, OnInit {
  @Input() options: SearchDropdownOption[] = [];
  @Input() disabled?: boolean = false;
  public filteredOptions: SearchDropdownOption[] = [];
  public isDropdownVisible = false;
  public selectFormControl: FormControl = new FormControl<SearchDropdownOption>(undefined);

  private _value: SearchDropdownOption = undefined;

  public get value(): SearchDropdownOption {
    return this._value;
  }

  public set value(value: SearchDropdownOption) {
    if (value !== null && value !== undefined && this.options.includes(value)) {
      this._value = value;
      this.isDropdownVisible = false;
      this.onChange(this.value);
    }
  }

  public ngOnInit() {
    this.filteredOptions = [...this.options];
    this.selectFormControl.valueChanges.subscribe((value) => {
      this.value = value;
    });
  }

  public onChange: any = () => {};

  public onTouch: any = () => {};

  /* istanbul ignore next */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /* istanbul ignore next */
  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  /* istanbul ignore next */
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /* istanbul ignore next */
  public writeValue(option: SearchDropdownOption): void {
    if (!this.disabled) {
      this.selectFormControl.patchValue(option);
    }
  }

  /**
   * Filters dropdown displayed options with its search input value
   * @param value the search input current value to be used for filtering
   */
  public filter(value: string) {
    if(value.length >= 2){
    this.filteredOptions = this.options.filter((o) => o.label.toLowerCase().includes(value.toLowerCase()));
  }
  }

  /**
   * Clears search input (on dropdown close) and resets filteredOptions
   */
  public onOpenedChange(opened: boolean, input: HTMLInputElement): void {
    this.onTouch();
    if (!opened) {
      input.value = null;
      this.filteredOptions = [...this.options];
    }
  }
}
