import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-redirection-header',
  templateUrl: './redirection-header.component.html',
  styleUrls: ['./redirection-header.component.scss'],
})
export class RedirectionHeaderComponent {
  @Input() currentPageName = '';
  @Input() location?: Location;
  @Output() exit?: EventEmitter<undefined> = new EventEmitter<undefined>();

  returnRouting() {
    if (this.location) {
      this.location.back();
    } else {
      this.exit.emit();
    }
  }
}
