import { Component } from '@angular/core';
import { ACCESS_CONTROL } from '../../models/enums/ACCESS_CONTROL';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  protected readonly ACCESS_CONTROL = ACCESS_CONTROL;
}
