import { FilterOptions } from './FilterOptions';
import { FILTER_TYPE } from './FILTER_TYPE';

export class Filter {
  filterName: string;
  type: FILTER_TYPE;
  columnReference: string;
  options?: FilterOptions;

  constructor(label: string, type: FILTER_TYPE, columnReference: string, options?: FilterOptions) {
    this.filterName = label;
    this.type = type;
    this.columnReference = columnReference;
    this.options = options;
  }
}
