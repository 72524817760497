export const environment = {
  production: false,
  debug: false,
  apiUrl: 'https://hgp-dev-weu-app-01.azurewebsites.net',
  issuer: 'https://login.microsoftonline.com/0d7c2487-28ef-40cc-af4b-2e1abc1f01a4/v2.0',
  clientId: '3481ddf8-321c-4031-966d-70d55b2b732d',
  scope: 'openid profile 3481ddf8-321c-4031-966d-70d55b2b732d/default',
  showDebugInformation: true,
  containerUrl: 'https://hgpdevweust01.blob.core.windows.net'
};
