import { Pipe, PipeTransform } from '@angular/core';
import { ORG_ROLE } from '../models/enums/ORG_ROLE';

@Pipe({
  name: 'orgRole',
})
export class OrgRolePipe implements PipeTransform {
  transform(role: ORG_ROLE | string): string {
    switch (role) {
      case ORG_ROLE.USER:
        return $localize`:Text localized in user info, partner info, etc. - Displays the entity role in the concerned organization (simple user role)@@org-role-user:User`;
      case ORG_ROLE.USER_ADMINISTRATOR:
        return $localize`:Text localized in user info, partner info, etc. - Displays the entity role in the concerned organization (admin of users role)@@org-role-user-admin:User administrator`;
      case ORG_ROLE.ORGANIZATION_ADMINISTRATOR:
        return $localize`:Text localized in user info, partner info, etc. - Displays the entity role in the concerned organization (admin of organization role)@@org-role-org-admin:Organization administrator`;
      default:
        return role;
    }
  }
}
