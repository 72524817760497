<div class="dialog-wrapper">
  <header>
    <h2>
      {{ title }}
    </h2>
    <button (click)="closeDialog()" class="large-icon icon-close" mat-button></button>
  </header>
  <div class="content-wrapper">
    <ng-content></ng-content>
  </div>
</div>
