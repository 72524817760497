<div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu">
  <button class="translation-select" mat-button>
    <span class="selected-language">{{ selectedLanguage.label }}</span>
    <mat-icon
      *ngIf="menuTrigger.menuOpen"
      [fontIcon]="'icon-chevron-up'"
      [fontSet]="'icomoon'"
      class="arrow"></mat-icon>
    <mat-icon
      *ngIf="!menuTrigger.menuOpen"
      [fontIcon]="'icon-chevron-down'"
      [fontSet]="'icomoon'"
      class="arrow"></mat-icon>
  </button>
</div>
<mat-menu #menu="matMenu" class="background-menu">
  <div *ngFor="let option of languages">
    <button (click)="onClick(option)" class="button-menu" mat-menu-item>
      <img [src]="option.icon" alt="language icon" class="logo-country" matPrefix />
      {{ option.label }}
    </button>
  </div>
</mat-menu>
