import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { UserAccountDto } from '../../models/dto/UserAccountDto';
import { OrganizationDto } from '../../models/dto/OrganizationDto';
import { UpdateUserCommandDto } from '../../models/dto/UpdateUserCommandDto';
import { PaginatedResult } from '../../models/dto/PaginatedResult';
import { Sort } from '@angular/material/sort';
import { SortCommandDto } from '../../models/dto/SortCommandDto';
import { OrgGroupDto } from '../../models/dto/OrgGroupDto';
import { FilledFilter } from '../../models/filters/FilledFilter';
import { FilterCommandDto } from '../../models/dto/FilterCommandDto';
import { UserDto } from '../../models/dto/UserDto';
import { WorkspaceDto } from '../../models/dto/WorkspaceDto'
import { UserAdminDto } from '../../models/dto/UserAdminDto';
import { ApplicationDto } from '../../models/dto/ApplicationDto';
import { LinkWsRolesDto } from '../../models/dto/LinkWsRolesDto';
import { UserEntraIdEnum } from '../../models/enums/UserEntraIdEnum';
import { ContactDto } from '../../models/dto/ContactDto';
import { UpdateUserProfileDto } from '../../models/dto/UpdateUserProfileDto';
import { OrganizationProfileDto } from '../../models/dto/OrganizationProfileDto';
import { UserProfileDto } from '../../models/dto/UserProfileDto';


@Injectable({
  providedIn: 'root',
})
export class UserApiService {

  private readonly BASE_URL: string = environment.apiUrl + '/users';

  constructor(private readonly httpClient: HttpClient) { }

  public getUserAccount(): Observable<UserAccountDto> {
    return this.httpClient.get<UserAccountDto>(`${this.BASE_URL}/account`);
  }

  public GetCurrentUserWorkspacesAndRoles(){
    return this.httpClient.get<LinkWsRolesDto[]>(`${this.BASE_URL}/workspaces-roles`);
  }

  public getCurrentUserOrganizations(
    page: number,
    pageSize: number,
    filters: FilledFilter[],
    sort: Sort
  ): Observable<PaginatedResult<OrganizationDto>> {
    let params: HttpParams = new HttpParams();
    params = params.append('page', page.toString());
    params = params.append('pageSize', pageSize.toString());
    if (filters && filters.length > 0) {
      params = params.append('filter', FilterCommandDto.fromFilledFilters(filters));
    }
    if (sort && sort.active && sort.direction) {
      params = params.append('sortBy', SortCommandDto.fromSort(sort));
    }
    return this.httpClient.get<PaginatedResult<OrganizationDto>>(`${this.BASE_URL}/my-organizations`, { params });
  }

  public getUserforAllWorkspace(
    organizationId: string,
    page: number,
    pageSize: number,
    filters: FilledFilter[],
    sort: Sort): Observable<PaginatedResult<UserDto>> {
    let params: HttpParams = new HttpParams();
    params = params.append('page', page.toString());
    params = params.append('pageSize', pageSize.toString());
    if (filters && filters.length > 0) {
      params = params.append('filter', FilterCommandDto.fromFilledFilters(filters));
    }
    if (sort && sort.active && sort.direction) {
      params = params.append('sortBy', SortCommandDto.fromSort(sort));
    }
    return this.httpClient.get<PaginatedResult<UserDto>>(`${this.BASE_URL}/${organizationId}/users`, { params });

  }

  public getCurrentUserAllRelatedUsers(
    page: number,
    pageSize: number,
    filters: FilledFilter[],
    sort: Sort): Observable<PaginatedResult<UserDto>> {
    let params: HttpParams = new HttpParams();
    params = params.append('page', page.toString());
    params = params.append('pageSize', pageSize.toString());
    if (filters && filters.length > 0) {
      params = params.append('filter', FilterCommandDto.fromFilledFilters(filters));
    }
    if (sort && sort.active && sort.direction) {
      params = params.append('sortBy', SortCommandDto.fromSort(sort));
    }
    return this.httpClient.get<PaginatedResult<UserDto>>(`${this.BASE_URL}/my-users`, { params });

  }


  public getAllUserForOrganization(
    orgId: string,
    page: number,
    pageSize: number,
    filters: FilledFilter[],
    sort: Sort
  ): Observable<PaginatedResult<UserDto>> {
    let params: HttpParams = new HttpParams();
    params = params.append('page', page.toString());
    params = params.append('pageSize', pageSize.toString());
    if (filters && filters.length > 0) {
      params = params.append('filter', FilterCommandDto.fromFilledFilters(filters));
    }
    if (sort && sort.active && sort.direction) {
      params = params.append('sortBy', SortCommandDto.fromSort(sort));
    }
    return this.httpClient.get<PaginatedResult<UserDto>>(`${this.BASE_URL}/my-organizations/${orgId}/users`, {
      params: params,
    });
  }

  public getUserWorkspaces(
    userId: string,
    page: number,
    pageSize: number,
    filters: FilledFilter[],
    sort: Sort,
    roles? : string
  ): Observable<PaginatedResult<WorkspaceDto>> {
    let params: HttpParams = new HttpParams();
    params = params.append('page', page.toString());
    params = params.append('pageSize', pageSize.toString());
    if (roles){
      params = params.append('roles',roles)
    }
    if (filters && filters.length > 0) {
      params = params.append('filter', FilterCommandDto.fromFilledFilters(filters));
    }
    if (sort && sort.active && sort.direction) {
      params = params.append('sortBy', SortCommandDto.fromSort(sort));
    }
    return this.httpClient.get<PaginatedResult<WorkspaceDto>>(`${this.BASE_URL}/my-workspaces`, { params });
  }

  public AllUserAdministrateurForWorkspace(
    workspaceid: string,
    page: number,
    pageSize: number,
    filters: FilledFilter[],
    sort: Sort
  ): Observable<PaginatedResult<UserDto>> {
    let params: HttpParams = new HttpParams();
    params = params.append('page', page.toString());
    params = params.append('pageSize', pageSize.toString());
    if (filters && filters.length > 0) {
      params = params.append('filter', FilterCommandDto.fromFilledFilters(filters));
    }
    if (sort && sort.active && sort.direction) {
      params = params.append('sortBy', SortCommandDto.fromSort(sort));
    }
    return this.httpClient.get<PaginatedResult<UserDto>>(`${this.BASE_URL}/${workspaceid}/users`, { params });
  }

  public getUserProperties() {
    return this.httpClient.get<UserProfileDto>(`${this.BASE_URL}/my-profile`)
  }
 
  public createUser(newUserDto: UserAdminDto): Observable<void> {
    return this.httpClient.post<void>(`${this.BASE_URL}`, newUserDto);
  }

  public updateUser(updatedUser: UpdateUserProfileDto): Observable<void> {
    return this.httpClient.put<void>(`${this.BASE_URL}/my-profile`, updatedUser);
  }

  //public removeUser(orgId: string, userId: string[]) {

  //  return this.httpClient.delete<void>(`${this.BASE_URL}/${orgId}/organizations`, {
  //    body: userId
  //  });
  //};

  public getAllUser(): Observable<UserDto> {
    return this.httpClient.get<UserDto>(`${this.BASE_URL}`);
  }

  public searchInternalUsersByEmail(email :string): Observable<UserDto[]> {
    let params: HttpParams = new HttpParams();
    params = params.append('email',email);
    return this.httpClient.get<UserDto[]>(( environment.apiUrl+'/internal-users'),{params});
  }

  public searchInternalUsersByDisplayName(displayName :string): Observable<UserDto[]> {
    let params: HttpParams = new HttpParams();
    params = params.append('displayName',displayName);
    return this.httpClient.get<UserDto[]>(( environment.apiUrl+'/internal-users'),{params});
  }

  public searchUsersAndContactsByEmail(email :string): Observable<ContactDto[]> {
    let params: HttpParams = new HttpParams();
    params = params.append('email',email);

    return this.httpClient.get<ContactDto[]>(( environment.apiUrl+'/users-and-contacts'),{params});
  }

  getUserApplications(filters: FilledFilter[],) : Observable<any>  {
    let params: HttpParams = new HttpParams();
    if (filters && filters.length > 0) {
      params = params.append('filter', FilterCommandDto.fromFilledFilters(filters));
    }
    return this.httpClient.get<ApplicationDto[]>(`${this.BASE_URL}/my-applications`,{params});
  }

  public UpdateUserWorkspaceRole(idRole: string, idUser: string, idWs: string) {
    const param = {
      idRole: idRole,
      idWs: idWs
    }
    return this.httpClient.put<void>(`${this.BASE_URL}/${idUser}/workspace-roles`, param);
  }

  
  public getUserProfileOrganizations(){
    return this.httpClient.get<OrganizationProfileDto[]>(`${this.BASE_URL}/my-profile/organizations`);
  }

    public getCurrentUserOrganizationWorkspaces(
      organizationId: string,
      page: number,
      pageSize: number,
      filters: FilledFilter[],
      sort: Sort
    ): Observable<PaginatedResult<WorkspaceDto>> {
      let params: HttpParams = new HttpParams();
      params = params.append('page', page.toString());
      params = params.append('pageSize', pageSize.toString());
      if (filters && filters.length > 0) {
        params = params.append('filter', FilterCommandDto.fromFilledFilters(filters));
      }
      if (sort && sort.active && sort.direction) {
        params = params.append('sortBy', SortCommandDto.fromSort(sort));
      }
      return this.httpClient.get<PaginatedResult<WorkspaceDto>>(`${this.BASE_URL}/my-organizations/${organizationId}/workspaces`, {
        params: params,
      });
    }
}
