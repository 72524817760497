import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from './auth.service';
import { Observable, finalize, map, tap } from 'rxjs';
import { GlobalLoadingService } from './global-loading.service';

/**
 * Function to be used for routing restriction.
 *
 * Returns `true` (boolean) if client has a valid token, else navigates to login page and returns `false` (Promise)
 */
export const ACCESS_CONTROL_GUARD = (route: ActivatedRouteSnapshot): boolean | Observable<boolean> => {
  const router: Router = inject(Router);
  const authService: AuthService = inject(AuthService);
  const globalLoadingService: GlobalLoadingService = inject(GlobalLoadingService);
  globalLoadingService.accessLoading = true;
  if (route.data['acl']) {
    return authService.verifyAccess(route.data['acl']).pipe(
      map((hasAccess: boolean) => {
        if (!hasAccess) {
          router.navigate(['/unauthorized']).finally();
        }
        return hasAccess;
      }),
      finalize(() => {
        globalLoadingService.accessLoading = false;
      })
    );
  } else {
    globalLoadingService.accessLoading = false;
    return true;
  }
};
