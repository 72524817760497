import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyNumbers]'
})
export class OnlyNumbersDirective {

  private regex: RegExp = new RegExp(/^[0-9]*$/g); // Régex pour accepter uniquement les chiffres
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight']; // Touches spéciales autorisées

  constructor(private el: ElementRef) { }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1 || (event.ctrlKey && (event.key === 'v' || event.key === 'c' || event.key === 'x'))) {
      return;
    }

    // Empêcher l'entrée de caractères qui ne sont pas des chiffres
    let current: string = this.el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedInput = clipboardData?.getData('text');
    
    if (pastedInput && !pastedInput.match(this.regex)) {
      event.preventDefault();
    }
  }
}
