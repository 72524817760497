import { UserEntraIdEnum } from "../enums/UserEntraIdEnum";
import { EntraIdTypeOption } from "./EntraIdTypeDto";
import { UpdateContactDto } from "./UpdateContactDto";

export class UpdateUserCommandDto {
  constructor(
    public id: string,
    public userName: string,
    public entraId_type: UserEntraIdEnum,
    public status: string,
    public lastSignIn: Date,
    public primaryOrg_Id: string,
    public contact_Id: string,
    public contact: UpdateContactDto
  ) { }
}
