import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from './src/environments/environment';

export const authCodeFlowConfig: AuthConfig = {
//  // redirectUri is dynamically set in app.component.ts constructor when initializing Oauth2 lib
  issuer: environment.issuer,
  clientId:environment.clientId,
  responseType: 'code',
  scope: environment.scope,
  showDebugInformation: environment.showDebugInformation,
  strictDiscoveryDocumentValidation: false,
};

//config prod

//export const authCodeFlowConfig: AuthConfig = {
//  // redirectUri is dynamically set in app.component.ts constructor when initializing Oauth2 lib
//  issuer: 'https://login.microsoftonline.com/c1a7fa9b-90b3-49ab-b5e2-1938a3a9188c/v2.0',
//  clientId: 'a1234af1-1aa7-4f7f-b8ed-5397302bd84a',
//  responseType: 'code',
//  scope: 'openid profile a1234af1-1aa7-4f7f-b8ed-5397302bd84a/default',
//  showDebugInformation: false,
//  strictDiscoveryDocumentValidation: false,
//};

// config dev

//export const authCodeFlowConfig: AuthConfig = {
//// redirectUri is dynamically set in app.component.ts constructor when initializing Oauth2 lib
//  issuer: 'https://login.microsoftonline.com/0d7c2487-28ef-40cc-af4b-2e1abc1f01a4/v2.0',
//  clientId: '3481ddf8-321c-4031-966d-70d55b2b732d',
//  responseType: 'code',
//  scope: 'openid profile 3481ddf8-321c-4031-966d-70d55b2b732d/default',
//  showDebugInformation: true,
//  strictDiscoveryDocumentValidation: false,
//};

// config uat
//export const authCodeFlowConfig: AuthConfig = {
//  // redirectUri is dynamically set in app.component.ts constructor when initializing Oauth2 lib
//  issuer: 'https://login.microsoftonline.com/0d7c2487-28ef-40cc-af4b-2e1abc1f01a4/v2.0',
//  clientId: '392b32f2-e8ff-4ba4-8af4-bbec444bf67a',
//  responseType: 'code',
//  scope: 'openid profile 392b32f2-e8ff-4ba4-8af4-bbec444bf67a/default',
//  showDebugInformation: true,
//  strictDiscoveryDocumentValidation: false,
//};
