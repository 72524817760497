import { Pipe, PipeTransform } from '@angular/core';
import { ROLE } from '../models/enums/ROLE';

@Pipe({
  name: 'rolePipe',
})
export class RolePipe implements PipeTransform {
  transform(rolePipe: ROLE | string): string {
    switch (rolePipe) {
      case ROLE.USER:
        return $localize`:Text localized in table - Displays the user role in workspace@@role-user:User`;
      case ROLE.WORKSPACE_MANAGER:
        return $localize`:Text localized in table - Displays the user role in workspace@@role-wsmanager:Workspace manager`;
      case ROLE.WORKSPACE_ADMINISTRATOR:
        return $localize`:Text localized in table - Displays the user role in workspace@@role-wsadmin:Workspace administrator`;
      default:
        return rolePipe;
    }
  }
}
