import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { UserAccountDto } from '../../models/dto/UserAccountDto';

@Injectable({
  providedIn: 'root',
})
export class UserAccountService {
  private _userAccountReplay: ReplaySubject<UserAccountDto> = new ReplaySubject<UserAccountDto>();
  public userAccountReplay$: Observable<UserAccountDto> = this._userAccountReplay.asObservable();

  private _userAccount: BehaviorSubject<UserAccountDto> = new BehaviorSubject<UserAccountDto>(null);

  public get userAccount(): UserAccountDto {
    return this._userAccount.getValue();
  }

  public set userAccount(userAccount: UserAccountDto) {
    this._userAccount.next(userAccount);
    this._userAccountReplay.next(userAccount);
  }
}
