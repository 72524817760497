import { SearchDropdownComponent } from './components/header/search-dropdown/search-dropdown.component';
import { NgModule } from '@angular/core';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { UserProfileComponent } from './components/header/user-profile/user-profile.component';
import { FilterComponent } from './components/filter/filter.component';
import { ToastComponent } from './components/toast/toast.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { TranslationSwitchComponent } from './components/header/translation-switch/translation-switch.component';
import { FaqComponent } from './components/header/user-profile/faq/faq.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { RedirectionHeaderComponent } from './components/redirection-header/redirection-header.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { UserStatusPipe } from './pipes/user-status.pipe';
import { OrgRolePipe } from './pipes/org-role.pipe';
import { ConfirmationDialogComponent } from './components/dialog/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationMenuComponent } from './components/confirmation-menu/confirmation-menu.component';
import { DialogWrapperComponent } from './components/dialog/dialog-wrapper/dialog-wrapper.component';
import { AccessControlDirective } from './directives/access-control.directive';
import { UserTooltipComponent } from './components/user-tooltip/user-tooltip.component';
import { OrgTypePipe } from './pipes/org-type.pipe';
import { AccountComponent } from './components/header/user-profile/account/account.component';
import { MatTabsModule } from '@angular/material/tabs';
import { RolePipe } from './pipes/role.pipe';
import { InternationalPhoneInputComponent } from './components/international-phone-input/international-phone-input.component';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { ContactTypePipe } from './pipes/contact-type.pipe';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    NavigationComponent,
    SearchDropdownComponent,
    UserProfileComponent,
    FilterComponent,
    BreadcrumbComponent,
    ToastComponent,
    TranslationSwitchComponent,
    UserStatusPipe,
    OrgRolePipe,
    FaqComponent,
    AccountComponent,
    RedirectionHeaderComponent,
    ConfirmationDialogComponent,
    ConfirmationMenuComponent,
    DialogWrapperComponent,
    AccessControlDirective,
    UserTooltipComponent,
    OrgTypePipe,
    ContactTypePipe,
    RolePipe,
    InternationalPhoneInputComponent,
    OnlyNumbersDirective,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatOptionModule,
    MatSelectModule,
    MatTooltipModule,
    MatMenuModule,
    MatIconModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    NavigationComponent,
    FilterComponent,
    SearchDropdownComponent,
    BreadcrumbComponent,
    FaqComponent,
    UserStatusPipe,
    OrgRolePipe,
    ConfirmationMenuComponent,
    DialogWrapperComponent,
    AccessControlDirective,
    RedirectionHeaderComponent,
    TranslationSwitchComponent,
    UserTooltipComponent,
    OrgTypePipe,
    RolePipe,
    InternationalPhoneInputComponent,
    ContactTypePipe
  ],
})
export class SharedModule {}
