import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SearchDropdownOption } from '../../models/SearchDropdownOption';
import { MenuItem } from '../../models/MenuItem';
import { OrganizationService } from '../../services/data/organization.service';
import { UserAccountService } from '../../services/data/user-account.service';
import { OrganizationLightDto } from '../../models/dto/OrganizationLightDto';
import { AuthService } from '../../services/auth.service';
import { ACCESS_CONTROL } from '../../models/enums/ACCESS_CONTROL';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  isExtensionVisible = true;
  items: MenuItem[] = [];

  public organizationsSdOptions: SearchDropdownOption[] = [];
  public organizationFormControl: FormControl<SearchDropdownOption>;
  protected readonly ACCESS_CONTROL = ACCESS_CONTROL;

  constructor(
    private readonly organisationService: OrganizationService,
    private readonly userAccountService: UserAccountService,
    private readonly authService: AuthService
  ) {
  }

  ngOnInit(): void {
    this.items = [
      {
        label: $localize`:Menu item localized in main menu (left), text also used in the breadcrumb - redirects to home page@@nav-home:Home`,
        icon: 'icon-home',
        routerLink: '/home',
        active: false,
      },
    ];

    this.authService.verifyAccess([ACCESS_CONTROL.ORGANIZATIONS_GET]).subscribe((hasAccess: boolean) => {
      if (hasAccess) {
        this.items.push({
          label: $localize`:Menu item localized in main menu (left), text also used in the breadcrumb   - redirects to organization page@@nav-organization:Organizations`,
          icon: 'icon-building',
          routerLink: '/organizations',
          active: false,
          isExpanded: false,
        });
      }
    });
    this.authService.verifyAccess([ACCESS_CONTROL.WORKSPACES_GET]).subscribe((hasAccess: boolean) => {
      if (hasAccess) {
        this.items.push({
          label: $localize`:Menu item localized in main menu (left), text also used in the breadcrumb   - redirects to organization page@@nav-workspace:Workspaces`,
          icon: 'icon-users',
          routerLink: '/workspaces',
          active: false,
          isExpanded: false,
        });
      }
    });
    this.authService.verifyAccess([ACCESS_CONTROL.APPLICATIONS_GET]).subscribe((hasAccess: boolean) => {
      if (hasAccess) {
        this.items.push({
          label: $localize`:Menu item localized in main menu (left), text also used in the breadcrumb  - redirects to apps links page@@nav-applications:Applications`,
          icon: 'icon-apps',
          routerLink: '/applications',
          active: false,
        });
      }
    })
    // TO DO A supprimer ou changer
    //this.setupOrganizationsSdOptions();

    //this.organizationFormControl.valueChanges.subscribe(() => {
    //  this.organisationService.selectedOrganization = this.organizationFormControl.value.value;
    //});
  }

  /**
   * Toggles the navbar (with/without text)
   */
  public toggleNavbar() {
    this.isExtensionVisible = !this.isExtensionVisible;
  }

  //private setupOrganizationsSdOptions() {
  //  this.organizationsSdOptions.push({
  //    label: this.userAccountService.userAccount.organization.displayName,
  //    value: this.userAccountService.userAccount.organization,
  //  });
  //  this.userAccountService.userAccount.organizationsPartner.forEach((org: OrganizationLightDto) => {
  //    this.organizationsSdOptions.push({ label: org.displayName, value: org });
  //  });
  //  this.userAccountService.userAccount.organizationsAdmin.forEach((org: OrganizationLightDto) => {
  //    this.organizationsSdOptions.push({ label: org.displayName, value: org });
  //  });

  //  // removal of duplicate organizations (same in admin and partner for example)
  //  this.organizationsSdOptions = this.organizationsSdOptions.reduce((optionsAccumulator, currentOption) => {
  //    if (!optionsAccumulator.find((organizationSdOption) => organizationSdOption.label === currentOption.label)) {
  //      optionsAccumulator.push(currentOption);
  //    }
  //    return optionsAccumulator;
  //  }, []);

  //  this.organizationsSdOptions.sort((a, b) => a.label.toUpperCase().localeCompare(b.label.toUpperCase()));

  //  this.organizationFormControl = new FormControl<SearchDropdownOption>(
  //    this.organizationsSdOptions.find((organization) => {
  //      return organization.value.id === this.userAccountService.userAccount.organization.id;
  //    })
  //  );
  //}
}
