<div style="display: flex;">
    <mat-form-field class="select-form-field">
        <mat-select [(ngModel)]="selectedCountry" (selectionChange)="onCountryChange($event)" panelClass="custom-select-panel">
            <mat-select-trigger>
                <span class="fi fi-{{ selectedCountry.toLowerCase() }}"></span>  + {{selectedCountryCode }}
            </mat-select-trigger>
            <mat-option *ngFor="let country of countries" [value]="country.name" >
                <span class="fi fi-{{ country.name.toLowerCase() }}"></span> {{getCountryFullName(country.name)}}   + {{country.code}} 
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="input-form-field" [ngStyle]="{'width': inputWidth}"> 
        <mat-label i18n="Input placeholder - Phone number@@input-phoneNumber">Phone number</mat-label>
        <input matInput appOnlyNumbers  [value]="phoneNumber" [value]="phoneNumber" (input)="onPhoneNumberChange($event)" />
    </mat-form-field>
</div>
<span  i18n="Information text - TO inform user that entered phone number is incorect@@phoneNumber-error" style="color: red;" *ngIf="validate()?.invalidPhoneNumber">
    Phone number is not valid
</span>
