import { map, Observable, tap } from 'rxjs';
import { UserAccountService } from './data/user-account.service';
import { inject } from '@angular/core';
import { ToastService } from './toast/toast.service';
import { OrganizationService } from './data/organization.service';
import { UserApiService } from './api/user-api.service';
import { GlobalLoadingService } from './global-loading.service';

/**
 * Using a Guard instead of a resolver cause Angular cycle is Guard>Resolver and we need this data for child guards
 * (even child guards are cycled before parent resolvers)
 */
export const DATA_RESOLUTION_GUARD = (): Observable<boolean> => {
  const userAccountService: UserAccountService = inject(UserAccountService);
  const toastService: ToastService = inject(ToastService);
  const organisationService: OrganizationService = inject(OrganizationService);
  const globalLoadingService: GlobalLoadingService = inject(GlobalLoadingService);
  globalLoadingService.dataLoading = true;
  let dataOk: boolean;
  return inject(UserApiService)
    .getUserAccount()
    .pipe(
      tap({
        next: (userAccount) => {
          userAccountService.userAccount = userAccount;
          //organisationService.selectedOrganization = userAccount.organization;
          dataOk = true;
          globalLoadingService.dataLoading = false;
        },
        error: () => {
          toastService.openError(
            $localize`:Text localized in event toast (poping up at top right on user action) -
            Informing the user that user account is unreachable@@lw-error-fetching-user-account:
            An error occurred while fetching the user's account. Please try again later or contact an administrator.`,
            { duration: 0 }
          );
          dataOk = false;
          globalLoadingService.dataLoading = false;
        },
      }),
      map(() => {
        return dataOk;
      })
    );
};
