import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { inject } from '@angular/core';

/**
 * Function to be used for routing restriction.
 *
 * Returns `true` (boolean) if client has a valid token, else navigates to login page and returns `false` (Promise)
 */
export const AUTH_GUARD = (): boolean | Promise<boolean> => {
  return inject(OAuthService).hasValidAccessToken()
    ? true
    : inject(Router)
        .navigate(['/login'])
        .then(() => false);
};
