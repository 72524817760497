import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-wrapper[dialogRef][title]',
  templateUrl: './dialog-wrapper.component.html',
  styleUrls: ['./dialog-wrapper.component.scss'],
})
export class DialogWrapperComponent<T> {
  @Input() dialogRef: MatDialogRef<T>;
  @Input() title: string;

  public closeDialog(): void {
    this.dialogRef.close(false);
  }
}
