<div class="layout-wrapper">
  <app-header></app-header>
  <div class="content-row">
    <app-navigation *ngIf="showNavBar"></app-navigation>
    <div class="content-wrapper">
      <app-breadcrumb *ngIf="showBreadCrumb"></app-breadcrumb>
      <div class="content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
