import { Pipe, PipeTransform } from '@angular/core';
import { ORG_TYPE } from '../models/enums/ORG_TYPE';

@Pipe({
  name: 'orgType',
})
export class OrgTypePipe implements PipeTransform {
  transform(type: ORG_TYPE | string): string {
    switch (type) {
      case ORG_TYPE.INTERNAL:
        return $localize`:Text localized in org info. - Displays the organization type@@org-type-internal:Internal`;
      case ORG_TYPE.CUSTOMER:
        return $localize`:Text localized in org info. - Displays the organization type@@org-type-customer:Customer`;
      default:
        return type;
    }
  }
}
