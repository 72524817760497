<div class="test-components">
  <h1>BUTTONS</h1>
  <p>Primary</p>
  <div class="row">
    <button class="primary-button" mat-button>Actif</button>
    <button class="primary-button" disabled mat-button>Inactif</button>
    <button class="primary-button small" mat-button>Small</button>
    <p>Icon only :</p>
    <button class="primary-button icon icon-search" mat-button></button>
  </div>
  <br />
  <div class="row">
    <button class="primary-button" mat-button>
      <mat-icon class="icon-search" matPrefix></mat-icon>
      Actif
    </button>
    <button class="primary-button" disabled mat-button>
      <mat-icon class="icon-search" matPrefix></mat-icon>
      Inactif
    </button>
    <button class="primary-button small" mat-button>
      <mat-icon class="icon-search" matPrefix></mat-icon>
      Small
    </button>
  </div>
  <p>Secondary</p>
  <div class="row">
    <button class="secondary-button" mat-button>Actif</button>
    <button class="secondary-button" disabled mat-button>Inactif</button>
    <button class="secondary-button small" mat-button>Small</button>
    <p>Icon only :</p>
    <button class="secondary-button icon icon-search" mat-button></button>
  </div>
  <br />
  <div class="row">
    <button class="secondary-button" mat-button>
      <mat-icon class="icon-search" matPrefix></mat-icon>
      Actif
    </button>
    <button class="secondary-button" disabled mat-button>
      <mat-icon class="icon-search" matPrefix></mat-icon>
      Inactif
    </button>
    <button class="secondary-button small" mat-button>
      <mat-icon class="icon-search" matPrefix></mat-icon>
      Small
    </button>
  </div>
  <br />
  <p>Third</p>
  <div class="row">
    <button class="third-button" mat-button>Actif</button>
    <button class="third-button" disabled mat-button>Inactif</button>
    <button class="third-button small" mat-button>Small</button>
    <p>Icon only :</p>
    <button class="third-button icon icon-search" mat-button></button>
  </div>
  <br />
  <div class="row">
    <button class="third-button" mat-button>
      <mat-icon class="icon-search" matPrefix></mat-icon>
      Actif
    </button>
    <button class="third-button" disabled mat-button>
      <mat-icon class="icon-search" matPrefix></mat-icon>
      Inactif
    </button>
    <button class="third-button small" mat-button>
      <mat-icon class="icon-search" matPrefix></mat-icon>
      Small
    </button>
  </div>

  <br />
  <hr />

  <h1>TOOLTIP</h1>
  <div class="row">
    <button
      [matTooltipPosition]="'above'"
      aria-label="Button that displays a tooltip when focused or hovered over"
      class="primary-button"
      mat-button
      matTooltip="Info about the action">
      Above
    </button>
    <button
      [matTooltipPosition]="'left'"
      aria-label="Button that displays a tooltip when focused or hovered over"
      class="primary-button"
      mat-button
      matTooltip="Info about the action">
      Left
    </button>
    <button
      [matTooltipPosition]="'below'"
      aria-label="Button that displays a tooltip when focused or hovered over"
      class="primary-button"
      mat-button
      matTooltip="Info about the action">
      Below
    </button>
    <button
      [matTooltipPosition]="'right'"
      aria-label="Button that displays a tooltip when focused or hovered over"
      class="primary-button"
      mat-button
      matTooltip="Info about the action">
      Right
    </button>
  </div>

  <br />
  <hr />

  <h1>FORM FIELDS</h1>
  <p>Input</p>
  <div class="row">
    <mat-form-field class="example-full-width">
      <mat-label>Favorite food</mat-label>
      <input [disabled]="true" matInput />
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <mat-icon class="icon-search" matPrefix></mat-icon>
      <mat-label>Favorite food</mat-label>
      <input matInput />
    </mat-form-field>
  </div>

  <p>Search dropdown</p>
  <app-search-dropdown [options]="sdOptions"></app-search-dropdown>

  <p>Select</p>
  <mat-form-field>
    <mat-label>Food</mat-label>
    <mat-select #select1 disabled value="babva">
      <mat-option *ngFor="let food of foods" [value]="food.value">
        {{ food.viewValue }}
      </mat-option>
      <mat-option [value]="'babva'"> baba</mat-option>
    </mat-select>
    <mat-icon *ngIf="select1.panelOpen" [fontIcon]="'icon-chevron-up'" [fontSet]="'icomoon'" matSuffix></mat-icon>
    <mat-icon *ngIf="!select1.panelOpen" [fontIcon]="'icon-chevron-down'" [fontSet]="'icomoon'" matSuffix></mat-icon>
  </mat-form-field>

  <p>Multiselect</p>
  <mat-form-field>
    <mat-label>Food</mat-label>
    <mat-select #select2 [disabled]="true" [formControl]="toppings" multiple>
      <mat-option *ngFor="let topping of toppingList" [value]="topping">
        {{ topping }}
      </mat-option>
      <mat-option disabled>Option 2 (disabled)</mat-option>
    </mat-select>
    <mat-icon *ngIf="select2.panelOpen" [fontIcon]="'icon-chevron-up'" [fontSet]="'icomoon'" matSuffix></mat-icon>
    <mat-icon *ngIf="!select2.panelOpen" [fontIcon]="'icon-chevron-down'" [fontSet]="'icomoon'" matSuffix></mat-icon>
  </mat-form-field>

  <p>Button select & multiselect</p>
  <div class="row">
    <div class="select-button">
      <button (click)="selectButton1.open()" class="primary-button small" mat-button>
        <mat-icon [fontIcon]="'icon-search'" [fontSet]="'icomoon'" matPrefix></mat-icon>
        Filter
      </button>
      <mat-select #selectButton1 [formControl]="toppings" multiple>
        <mat-option *ngFor="let topping of toppingList" [value]="topping">
          {{ topping }}
        </mat-option>
        <mat-option disabled>Option 2 (disabled)</mat-option>
      </mat-select>
    </div>
    <div class="select-button">
      <button (click)="selectButton2.open()" class="primary-button small" mat-button>
        <mat-icon [fontIcon]="'icon-search'" [fontSet]="'icomoon'" matPrefix></mat-icon>
        Filter
      </button>
      <mat-select #selectButton2 [formControl]="toppings">
        <mat-option *ngFor="let topping of toppingList" [value]="topping">
          {{ topping }}
        </mat-option>
        <mat-option disabled>Option 2 (disabled)</mat-option>
      </mat-select>
    </div>
  </div>

  <p>Filter Component</p>
  <app-filter [filters]="filters"></app-filter>
</div>
