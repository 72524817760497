import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { ToastData } from '../../models/toast/ToastData';
import { TOAST_TYPE } from '../../models/toast/TOAST_TYPE';

@Component({
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent {
  public icon:
    | 'icon-success-circle-fill'
    | 'icon-warning-triangle-fill'
    | 'icon-error-circle-fill'
    | 'icon-info-circle-fill';
  public toastTypeCssClass: 'success' | 'error' | 'warning' | 'info';

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) protected readonly data: ToastData,
    private readonly matSnackBarRef: MatSnackBarRef<ToastComponent>
  ) {
    switch (data.type) {
      case TOAST_TYPE.SUCCESS:
        this.icon = 'icon-success-circle-fill';
        this.toastTypeCssClass = 'success';
        break;
      case TOAST_TYPE.ERROR:
        this.icon = 'icon-error-circle-fill';
        this.toastTypeCssClass = 'error';
        break;
      case TOAST_TYPE.WARNING:
        this.icon = 'icon-warning-triangle-fill';
        this.toastTypeCssClass = 'warning';
        break;
      case TOAST_TYPE.INFO:
        this.icon = 'icon-info-circle-fill';
        this.toastTypeCssClass = 'info';
        break;
    }
  }

  public close() {
    this.matSnackBarRef.dismiss();
  }
}
