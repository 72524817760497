import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ToastComponent } from '../../components/toast/toast.component';
import { ToastOptions } from '../../models/toast/ToastOptions';
import { TOAST_TYPE } from '../../models/toast/TOAST_TYPE';
import { ToastData } from '../../models/toast/ToastData';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private readonly matSnackBar: MatSnackBar) {}

  public openSuccess(message: string, options?: ToastOptions) {
    this.openSnack(TOAST_TYPE.SUCCESS, message, options);
  }

  public openError(message: string, options?: ToastOptions) {
    this.openSnack(TOAST_TYPE.ERROR, message, options);
  }

  public openWarning(message: string, options?: ToastOptions) {
    this.openSnack(TOAST_TYPE.WARNING, message, options);
  }

  public openInfo(message: string, options?: ToastOptions) {
    this.openSnack(TOAST_TYPE.INFO, message, options);
  }

  private openSnack(type: TOAST_TYPE, message: string, options?: ToastOptions) {
    let panelClass: 'success' | 'error' | 'warning' | 'info';
    switch (type) {
      case TOAST_TYPE.SUCCESS:
        panelClass = 'success';
        break;
      case TOAST_TYPE.ERROR:
        panelClass = 'error';
        break;
      case TOAST_TYPE.WARNING:
        panelClass = 'warning';
        break;
      case TOAST_TYPE.INFO:
        panelClass = 'info';
        break;
    }

    const config: MatSnackBarConfig<ToastData> = new MatSnackBarConfig<ToastData>();
    config.verticalPosition = 'top';
    config.horizontalPosition = 'right';
    config.panelClass = panelClass;
    config.duration = 5000;
    config.data = {
      type: type,
      message: message,
      closable: true,
    } as ToastData;
    if (options?.closable === true || options?.closable === false) {
      config.data.closable = options.closable;
    }
    if (options?.duration >= 0) {
      config.duration = options.duration;
    }
    this.matSnackBar.openFromComponent(ToastComponent, config);
  }
}
