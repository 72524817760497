import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { debounceTime } from 'rxjs';
import { FilledFilter } from '../../models/filters/FilledFilter';
import { Filter } from '../../models/filters/Filter';
import { FILTER_TYPE } from '../../models/filters/FILTER_TYPE';

/**
 * Filter component which permits choosing options for the associated columns of a table. Features :
 * - multiselect toggle for the available filters that haven't been chosen by default
 * - able de to add default filters that will always be visible
 *
 * Attributes :
 * - the `filters` attribute is **required** and must contain an array of custom filters {@link Filter}
 * - the `filledFilters` attribute is the output and will return an array of filledFilters{@link FilledFilter}
 */
@Component({
  selector: 'app-filter[filters]',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
  @Output() public filledFilters: EventEmitter<FilledFilter[]> = new EventEmitter<FilledFilter[]>();
  @Input() public filters: Filter[];
  @Input() public towCraracterFilter: number = 0;
  public chosenFilters: FormControl<string[]> = new FormControl([]);
  public nonMandatoryFilters: Filter[] = [];
  public formGroup: FormGroup;
  public FILTER_TYPE = FILTER_TYPE;

  // filterMap sert ici à pouvoir faire une association entre le nom du formControl et
  // le filtre associé afin de pouvoir utiliser les fonctionnalités du filtre dans le HTML
  filtersMap: Map<string, Filter> = new Map<string, Filter>();

  constructor(private readonly formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({});
    this.mandatoryFiltersAndMapInit();
    this.onChangeFormGroup();
    this.nonMandatoryFiltersInit();
  }

  toggleFilter(event: MatOptionSelectionChange<string>, controlName: string) {
    if (event.source.selected) {
      this.formGroup.addControl(controlName, new FormControl(null));
    } else {
      this.formGroup.removeControl(controlName);
    }
  }

  private onChangeFormGroup() {
    this.formGroup.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.filledFilters.emit(
        Object.entries(this.formGroup.controls)
          .map((a) => {
            return new FilledFilter(a[0], a[1].value, this.filters.find((f) => f.columnReference === a[0]).type);
          })
          .filter((ff: FilledFilter) => ff.value !== null && ff.value !== undefined && ((ff.value.length > this.towCraracterFilter) || (ff.value.length > 0 && ff.type !== 'STRING')))
      );
    });
  }

  private nonMandatoryFiltersInit() {
    this.nonMandatoryFilters = this.filters.filter((filter) => {
      return !filter.options?.alwaysActive;
    });
  }

  private mandatoryFiltersAndMapInit() {
    this.filters.forEach((filter) => {
      if (filter.options?.alwaysActive) {
        this.formGroup.addControl(filter.columnReference, new FormControl(null));
      }
      this.filtersMap.set(filter.columnReference, filter);
    });
  }
}
