<div class="faq-wrapper">
  <div class="faq-header">
    <app-redirection-header [currentPageName]="'FAQ'" [location]="location"></app-redirection-header>
    <mat-progress-bar *ngIf="isLoading" mode="query"></mat-progress-bar>
  </div>
  <div class="question-wrapper">
    <mat-expansion-panel *ngFor="let question of faq" class="questions">
      <mat-expansion-panel-header>
        <mat-panel-title> {{ question.order }}. {{ question.question }} </mat-panel-title>
      </mat-expansion-panel-header>
      {{ question.answer }}
    </mat-expansion-panel>
  </div>
</div>
