import { Component, OnInit } from '@angular/core';
import { FaqApiService } from '../../../../services/api/faq-api.service';
import { FaqDto } from '../../../../models/dto/FaqDto';
import { Location } from '@angular/common';
import { ToastService } from '../../../../services/toast/toast.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
  public isLoading: boolean = true;
  public faq: FaqDto[] = [];

  constructor(
    private readonly faqApiService: FaqApiService,
    public location: Location,
    private readonly toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.requestFaqs();
  }

  requestFaqs() {
    this.isLoading = true;
    this.faqApiService.getAllFaqs().subscribe({
      next: (result: FaqDto[]) => {
        result.sort(function (a, b) {
          return a.order - b.order;
        });
        result.forEach((value, key) => {
          value.order = key + 1;
          this.faq.push(value);
        });
        this.isLoading = false;
      },
      error: () => {
        this.toastService.openError(
          $localize`:Text localized in event toast (poping up at top right on user action) -
            Informing the user that the questions are unreachable@@f-error-fetching-questions:
            An error occurred while fetching the questions. Please try again later or contact an administrator.`,
          { duration: 0 }
        );
        this.isLoading = false;
      },
    });
  }
}
