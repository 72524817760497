import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ACCESS_CONTROL } from '../models/enums/ACCESS_CONTROL';
import { AuthService } from '../services/auth.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appAccessControl]',
})
export class AccessControlDirective implements OnInit, OnDestroy {
  @Input() appAccessControl: ACCESS_CONTROL[];
  private subscription: Subscription = new Subscription();

  constructor(
    private authService: AuthService,
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<unknown>
  ) {}

  public ngOnInit(): void {
    this.applyAccessStrategy();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private applyAccessStrategy(): void {
    this.subscription.add(
      this.authService.verifyAccess(this.appAccessControl).subscribe((hasAccess: boolean) => {
        if (hasAccess) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      })
    );
  }
}
