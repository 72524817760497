import { Component, OnInit } from '@angular/core';
import { ToastService } from '../../../../services/toast/toast.service';
import { UserApiService } from '../../../../services/api/user-api.service';
import { UserAccountService } from '../../../../services/data/user-account.service';
import { UserDto } from '../../../../models/dto/UserDto';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UpdateUserCommandDto } from '../../../../models/dto/UpdateUserCommandDto';
import { Router } from '@angular/router';
import { FilledFilter } from '../../../../models/filters/FilledFilter';
import { PaginationMetadata } from '../../../../models/dto/PaginationMetadata';
import { Sort } from '@angular/material/sort';
import { OrganizationDto } from '../../../../models/dto/OrganizationDto';
import { PaginatedResult } from '../../../../models/dto/PaginatedResult';
import { ACCESS_CONTROL } from '../../../../models/enums/ACCESS_CONTROL';
import { UpdateContactDto } from '../../../../models/dto/UpdateContactDto';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {
  public accountTitle = 'Account';
  public isLoading: boolean = true;
  public userProperties: UserDto;
  public userOrgPropertires: OrganizationDto[];
  private modifyUserDto: UpdateUserCommandDto;
  private ModfiyContactDto: UpdateContactDto;
  public form: FormGroup;
  public showId: string;
  public chevron: boolean = false;

  private currentFilters: FilledFilter[] = [];
  public pageSizeOptions: number[] = [20, 50, 100, 500];
  public sessionObject: any;
  public currentPagination: PaginationMetadata = {
    currentPage: 1,
    pageSize: this.pageSizeOptions[0],
  };
  public currentSort: Sort = {
    active: null,
    direction: null,
  };
  protected readonly ACCESS_CONTROL = ACCESS_CONTROL;

  constructor(
    private readonly userApiService: UserApiService,
    private readonly toastService: ToastService,
    private readonly userAccountService: UserAccountService,
    readonly router: Router,
    private readonly formBuilder: FormBuilder,
  ) { }

  public async ngOnInit(): Promise<void> {
    await this.getUserProperties();
    await this.getUserorganization();
    this.initForm();
  }

  public submit(): void {
    this.isLoading = true;
    this.ModfiyContactDto = new UpdateContactDto(
      this.userProperties.contact.id,
      this.userProperties.contact.displayName,
      this.userProperties.contact.email,
      this.form.get('lastName').value,
      this.form.get('firstName').value,
      this.form.get('jobTitle').value,
      this.form.get('phoneNumber').value
    );

    this.modifyUserDto = new UpdateUserCommandDto(
      this.userProperties.id,
      this.form.get('userName').value,
      this.userProperties.entraId_type,
      this.userProperties.status,
      this.userProperties.lastSignIn,
      this.userProperties.primaryOrg_Id,
      this.ModfiyContactDto.id,
      this.ModfiyContactDto
    );

    this.userApiService.updateUser(this.modifyUserDto)
      .subscribe({
        next: () => {
          this.toastService.openSuccess(
            $localize`:Text localized in event toast (poping up at top right on user action) -
              Informing the user that user was successfully updated@@auu-update-complete:
              The user has been updated successfully.`,
            { duration: 5000 }
          );
          
        },
        error: () => {
          this.toastService.openError(
            $localize`:Text localized in event toast (poping up at top right on user action) -
              Informing the user that user was not updated@@auu-error-update-user:
              An error occurred while updating this user. Please try again later or contact an administrator.`,
            { duration: 0 }
          );
          this.isLoading = false;
        },
      });
  }

  getUserProperties() {
    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;
      this.userApiService.getUserProperties(this.userAccountService.userAccount.id).subscribe({
        next: (result: UserDto) => {
          this.userProperties = result;
          resolve();
          this.isLoading = false;
        },
        error: () => {
          this.toastService.openError(
            $localize`:Text localized in event toast (poping up at top right on user action) -
            Informing the user that the questions are unreachable@@f-error-fetching-questions:
            An error occurred while fetching the questions. Please try again later or contact an administrator.`,
            { duration: 0 }
          );
          reject();
          this.isLoading = false;
        },
      });
    });
  }

  getUserorganization() {
    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;
      this.userApiService.getUserOrganizations(this.userAccountService.userAccount.id,
        this.currentPagination.currentPage,
        this.currentPagination.pageSize,
        this.currentFilters,
        this.currentSort).subscribe({
          next: (result: PaginatedResult<OrganizationDto>) => {
            this.userOrgPropertires = result.items;
          resolve();
          this.isLoading = false;
        },
        error: () => {
          this.toastService.openError(
            $localize`:Text localized in event toast (poping up at top right on user action) -
            Informing the user that the questions are unreachable@@f-error-fetching-questions:
            An error occurred while fetching the questions. Please try again later or contact an administrator.`,
            { duration: 0 }
          );
          reject();
          this.isLoading = false;
        },
      });
    });
  }

  redirectToHome() {
    this.router.navigate(['/home']).finally();
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      lastName: new FormControl(
        {
          value: this.userProperties.contact.lastName,
          disabled: false,
        }
      ),
      userName: new FormControl(
        {
          value: this.userProperties.userName,
          disabled: false,
        }
      ),
      jobTitle: new FormControl(
        {
          value: this.userProperties.contact.jobTitle,
          disabled: false,
        }
      ),
      firstName: new FormControl(
        {
          value: this.userProperties.contact.firstName,
          disabled: false,
        }
      ),
      phoneNumber: new FormControl(
        {
          value: this.userProperties.contact.phoneNumber,
          disabled: false,
        }
      )
    });

    //this.form.patchValue({
    //  phoneNumberBis: this.userProperties.contact.phoneNumber
    //})
  }

  sendMail() {

  }

  openList(index: number) {
    if (this.showId != index.toString()) {
      this.showId = index.toString();
      this.chevron = true;
    } else {
      this.showId = null;
      this.chevron = false;
    }
  }
}
