import { FILTER_TYPE } from './FILTER_TYPE';
import { FilterChoice } from './FilterChoice';

export class FilledFilter {
  column: string;
  value: string | FilterChoice[];
  type: FILTER_TYPE;

  constructor(column: string, value: string | FilterChoice[], type: FILTER_TYPE) {
    this.column = column;
    this.value = value;
    this.type = type;
  }
}
