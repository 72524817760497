import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FaqDto } from '../../models/dto/FaqDto';

@Injectable({
  providedIn: 'root',
})
export class FaqApiService {
  private readonly BASE_URL: string = environment.apiUrl + '/faq';

  constructor(private readonly httpClient: HttpClient) {}

  public getAllFaqs(): Observable<FaqDto[]> {
    return this.httpClient.get<FaqDto[]>(`${this.BASE_URL}`);
  }
}
