import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SharedService {

  private organizationId: string;
  private workspaceId: string;

  set organizationIdToEdit(organizationId) {
    this.organizationId = organizationId;
  }

  get organizationIdToEdit() {
    return this.organizationId;
  }

  set workspaceIdToEdit(workspaceId) {
    this.workspaceId = workspaceId;
  }

  get workspaceIdToEdit() {
    return this.workspaceId;
  }

}
