export enum ACCESS_CONTROL {
  GENERATE_TOKEN = 'GENERATE_TOKEN',

  FAQ_GET = 'FAQ_GET',

  ORGANIZATION_GET = 'ORGANIZATION_GET',
  ORGANIZATIONS_GET = 'ORGANIZATIONS_GET',
  //ORGANIZATION_POST = 'ORGANIZATION_POST',
  //ORGANIZATION_DELETE = 'ORGANIZATION_DELETE',
  //ORGANIZATION_PUT = 'ORGANIZATION_PUT',
  ORGANIZATION_APPLICATIONS_GET = 'ORGANIZATION_APPLICATIONS_GET',
  //ORGANIZATION_APPLICATIONS_POST = 'ORGANIZATION_APPLICATIONS_POST',
  //ORGANIZATION_APPLICATIONS_DELETE = 'ORGANIZATION_APPLICATIONS_DELETE',
  ORGANIZATION_CONTACTS_GET = 'ORGANIZATION_CONTACTS_GET',
  //ORGANIZATION_CONTACTS_DELETE = 'ORGANIZATION_CONTACTS_DELETE',
  ORGANIZATION_WORKSPACES_GET = 'ORGANIZATION_WORKSPACES_GET',
  ORGANIZATION_WORKSPACES_POST = 'ORGANIZATION_WORKSPACES_POST',
  ORGANIZATION_WORKSPACES_DELETE = 'ORGANIZATION_WORKSPACES_DELETE',
  ORGANIZATION_MEMBERS_GET = 'ORGANIZATION_MEMBERS_GET',
  ORGANIZATION_WORKSPACE_EXPORT = 'ORGANIZATION_WORKSPACE_EXPORT',
  ORGANIZATION_WSCREATORS_GET = 'ORGANIZATION_WSCREATORS_GET',
  ORGANIZATION_ADMIN_INFOS = 'ORGANIZATION_ADMIN_INFOS',
  


  WORKSPACE_GET = 'WORKSPACE_GET',
  WORKSPACES_GET = 'WORKSPACES_GET',
  WORKSPACE_DELETE = 'WORKSPACE_DELETE',
  WORKSPACE_PUT = 'WORKSPACE_PUT',
  WORKSPACE_POST = 'WORKSPACE_POST',
  WORKSPACE_MEMBERS_GET = 'WORKSPACE_MEMBERS_GET',
  WORKSPACE_MEMBERS_POST = 'WORKSPACE_MEMBERS_POST',
  WORKSPACE_MEMBERS_DELETE = "WORKSPACE_MEMBERS_DELETE",
  WORKSPACE_MEMBERS_EXPORT = 'WORKSPACE_MEMBERS_EXPORT',
  WORKSPACE_APPLICATIONS_POST = 'WORKSPACE_APPLICATIONS_POST',
  WORKSPACE_APPLICATIONS_DELETE = 'WORKSPACE_APPLICATIONS_DELETE',
  WORKSPACE_APPLICATIONS_GET = 'WORKSPACE_APPLICATIONS_GET',
  WORKSPACE_CONTACTS_DELETE = 'WORKSPACE_CONTACTS_DELETE',
  WORKSPACE_CONTACTS_POST = 'WORKSPACE_CONTACTS_POST',
  WORKSPACE_CONTACTS_GET = 'WORKSPACE_CONTACTS_GET',

  //USER_GET = 'USER_GET',
  //USER_POST = 'USER_POST',
  //USER_PUT = 'USER_PUT',
  //USER_DELETE = 'USER_DELETE',
  USER_MEMBERS_GET = 'USER_MEMBERS_GET',
  USER_WORKSPACES_GET = 'USER_WORKSPACES_GET',
  //USER_WORKSPACES_DELETE = 'USER_WORKSPACES_DELETE',
  //USER_WORKSPACES_POST = 'USER_WORKSPACES_POST',
  USER_ORGANIZATIONS_GET = 'USER_ORGANIZATIONS_GET',
  //USER_APPLICATIONS_GET = 'USER_APPLICATIONS_GET',
  USER_MEMBERS_EXPORT = 'USER_MEMBERS_EXPORT',

  APPLICATIONS_GET = 'APPLICATIONS_GET',
  //APPLICATION_PUT = 'APPLICATION_PUT',
  //APPLICATION_DELETE = 'APPLICATION_DELETE',
  //APPLICATION_POST = 'APPLICATION_POST',
  //APPLICATION_ORGANIZATIONS_POST = 'APPLICATION_ORGANIZATIONS_POST',
  //APPLICATION_ORGANIZATIONS_DELETE = 'APPLICATION_ORGANIZATIONS_DELETE',
  //APPLICATION_WORKSPACES_POST = 'APPLICATION_WORKSPACES_POST',
  //APPLICATION_WORKSPACES_DELETE = 'APPLICATION_WORKSPACES_DELETE',

  ACCOUNT_PUT = 'ACCOUNT_PUT',

  ADMIN_ORGANIZATIONS_GET = 'ADMIN_ORGANIZATIONS_GET',
  ADMIN_ORGANIZATION_PUT = 'ADMIN_ORGANIZATION_PUT',
  ADMIN_ORGANIZATION_POST = 'ADMIN_ORGANIZATION_POST',
  ADMIN_ORGANIZATION_DELETE = 'ADMIN_ORGANIZATION_DELETE',
  ADMIN_ORGANIZATION_APPLICATIONS_DELETE = 'ADMIN_ORGANIZATION_APPLICATIONS_DELETE',
  ADMIN_ORGANIZATION_APPLICATIONS_POST = 'ADMIN_ORGANIZATION_APPLICATIONS_POST',
  ADMIN_ORGANIZATION_APPLICATIONS_GET = 'ADMIN_ORGANIZATION_APPLICATIONS_GET',
  ADMIN_ORGANIZATION_WORKSPACES_DELETE = 'ADMIN_ORGANIZATION_WORKSPACES_DELETE',
  ADMIN_ORGANIZATION_WORKSPACES_GET = 'ADMIN_ORGANIZATION_WORKSPACES_GET',
  ADMIN_ORGANIZATION_WORKSPACES_POST = 'ADMIN_ORGANIZATION_WORKSPACES_POST',
  ADMIN_ORGANIZATION_CONTACTS_POST = 'ADMIN_ORGANIZATION_CONTACTS_POST',
  ADMIN_ORGANIZATION_CONTACTS_DELETE = 'ADMIN_ORGANIZATION_CONTACTS_DELETE',
  ADMIN_ORGANIZATION_MEMBERS_GET = 'ADMIN_ORGANIZATION_MEMBERS_GET',
  ADMIN_ORGANIZATION_MEMBERS_DELETE = 'ADMIN_ORGANIZATION_MEMBERS_DELETE',
  ADMIN_ORGANIZATION_WSCREATORS_POST = 'ADMIN_ORGANIZATION_WSCREATORS_POST',
  ADMIN_ORGANIZATION_WSCREATORS_DELETE = 'ADMIN_ORGANIZATION_WSCREATORS_DELETE',
  
  ADMIN_WORKSPACES_GET = 'ADMIN_WORKSPACES_GET',
  ADMIN_WORKSPACE_PUT = 'ADMIN_WORKSPACE_PUT',
  ADMIN_WORKSPACE_POST = 'ADMIN_WORKSPACE_POST',
  ADMIN_WORKSPACE_DELETE = 'ADMIN_WORKSPACE_DELETE',
  ADMIN_WORKSPACE_MEMBERS_DELETE = 'ADMIN_WORKSPACE_MEMBERS_DELETE',
  ADMIN_WORKSPACE_MEMBERS_GET = 'ADMIN_WORKSPACE_MEMBERS_GET',
  ADMIN_WORKSPACE_MEMBERS_POST = 'ADMIN_WORKSPACE_MEMBERS_POST',
  ADMIN_WORKSPACE_APPLICATIONS_POST = 'ADMIN_WORKSPACE_APPLICATIONS_POST',
  ADMIN_WORKSPACE_APPLICATIONS_GET = 'ADMIN_WORKSPACE_APPLICATIONS_GET',
  ADMIN_WORKSPACE_CONTACTS_GET = 'ADMIN_WORKSPACE_CONTACTS_GET',

  ADMIN_USERS_GET = 'ADMIN_USERS_GET',
  ADMIN_USER_DELETE = 'ADMIN_USER_DELETE',
  ADMIN_USER_POST = 'ADMIN_USER_POST',
  ADMIN_USER_PUT = 'ADMIN_USER_PUT',
  ADMIN_USER_WORKSPACES_DELETE = 'ADMIN_USER_WORKSPACES_DELETE',
  ADMIN_USER_WORKSPACES_POST = 'ADMIN_USER_WORKSPACES_POST',
  ADMIN_USER_WORKSPACES_GET = 'ADMIN_USER_WORKSPACES_GET',
  ADMIN_USER_ORGANIZATIONS_GET = 'ADMIN_USER_ORGANIZATIONS_GET',

  ADMIN_APPLICATIONS_GET = 'ADMIN_APPLICATIONS_GET',
  ADMIN_APPLICATION_POST = 'ADMIN_APPLICATION_POST',
  ADMIN_APPLICATION_PUT = 'ADMIN_APPLICATION_PUT',
  ADMIN_APPLICATION_DELETE = 'ADMIN_APPLICATION_DELETE',
  ADMIN_APPLICATION_WORKSPACES_DELETE = 'ADMIN_APPLICATION_WORKSPACES_DELETE',
  ADMIN_APPLICATION_WORKSPACES_POST = 'ADMIN_APPLICATION_WORKSPACES_POST',
  ADMIN_APPLICATION_ORGANIZATIONS_DELETE = 'ADMIN_APPLICATION_ORGANIZATIONS_DELETE',
  ADMIN_APPLICATION_ORGANIZATIONS_POST = 'ADMIN_APPLICATION_ORGANIZATIONS_POST',
  ADMIN_APPLICATION_ORGANIZATIONS_GET = 'ADMIN_APPLICATION_ORGANIZATIONS_GET',

  ADMIN_ORGANIZATIONS = 'ADMIN_ORGANIZATIONS',
  ADMIN_WORKSPACES = 'ADMIN_WORKSPACES',
  ADMIN_USERS = 'ADMIN_USERS',
  ADMIN_APPLICATIONS ='ADMIN_APPLICATIONS',
  ADMIN_DOMAINS ='ADMIN_DOMAINS',

  ADMIN_DOMAIN_DELETE = 'ADMIN_DOMAIN_DELETE',
  ORGANIZATION_DOMAIN_POST = 'ORGANIZATION_DOMAIN_POST',
  ORGANIZATION_DOMAINS_DELETE = 'ORGANIZATION_DOMAINS_DELETE'




}
