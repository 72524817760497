export enum OPERAND {
  CONTAINS = 'CONTAINS',
  EQUALS = 'EQUALS',
  STARTSWITH = 'STARTSWITH',
  ENDSWITH = 'ENDSWITH',
  IN = 'IN',
  DIFF = 'DIFF', // Numeric inequality
  GT = 'GT', // Greater than
  GTE = 'GTE', // Greater than or equal
  LT = 'LT', // Less than
  LTE = 'LTE', // Less than or equal
  BEFORE = 'BEFORE', // Date before
  AFTER = 'AFTER', // Date after
}
