import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { SwitchLanguage } from '../../../models/SwitchLanguage';

@Component({
  selector: 'app-translation-switch',
  templateUrl: './translation-switch.component.html',
  styleUrls: ['./translation-switch.component.scss'],
})
export class TranslationSwitchComponent implements OnInit {
  languages = [
    { code: 'en-US', label: 'EN', icon: 'assets/image/countries/US.png' },
    { code: 'fr-FR', label: 'FR', icon: 'assets/image/countries/FR.png' },
  ] as SwitchLanguage[];
  selectedLanguage = { code: 'en-US', label: 'EN', icon: 'assets/image/countries/US.png' };

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const domain = window.location.href;
      const splitURL = domain.split('/');
      for (let s of splitURL.entries()) {
        if (s[1].match(/^([a-zA-Z]{2}(-[a-zA-Z]{2})?)$/)) {
          this.selectedLanguage = this.languages.find((language) => {
            return language.code === splitURL[s[0]];
          });
          break;
        }
      }
    }
  }

  onClick(option: any) {
    if (isPlatformBrowser(this.platformId)) {
      let currentRoute = window.location.href;
      currentRoute = currentRoute.replace(this.selectedLanguage.code.toString(), option.code.toString());
      window.location.href = currentRoute;
      this.selectedLanguage = option;
    }
  }
}
