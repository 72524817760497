<div class="breadcrumb-layout">
  <h1 class="org-name">{{ organizationName | titlecase}}</h1>
  <div class="breadcrumb-items">
    <div *ngFor="let breadcrumb of breadcrumbs; let last = last">
      <!--<a [routerLink]="breadcrumb.url" [queryParams]="{orgId:breadcrumb.orgId,wsId:breadcrumb.wsId}" class="breadcrumb-item">{{ breadcrumb.label }}</a>-->
      <a (click)="navigateTo(breadcrumb.idOrg,breadcrumb.idWs,breadcrumb.url)" class="breadcrumb-item">{{ breadcrumb.label }}</a>

      <span *ngIf="!last" class="separator"> >&nbsp; </span>
    </div>
  </div>
</div>
