import { CONTACT_TYPE } from "../enums/CONTACT_TYPE";

export class UpdateContactDto {

  constructor(
    public id?: string,
    public displayName?: string,
    public email?: string,
    public lastName?: string,
    public firstName?: string,
    public jobTitle?: string,
    public phoneNumber?: string,
    public isUser?: boolean) { }
}
