import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConfirmationMenuData } from '../../models/ConfirmationMenuData';

@Component({
  selector: 'app-confirmation-menu',
  templateUrl: './confirmation-menu.component.html',
  styleUrls: ['./confirmation-menu.component.scss'],
})
export class ConfirmationMenuComponent {
  @Input() data: ConfirmationMenuData;

  @Output() reject: EventEmitter<void> = new EventEmitter<void>();
  @Output() resolve: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  public rejectMenu(): void {
    this.reject.emit();
  }

  public resolveMenu(): void {
    this.resolve.emit();
  }
}
