import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { parsePhoneNumberFromString, getCountries, getCountryCallingCode } from 'libphonenumber-js';
import { countries } from '../../models/enums/iso-countries';


@Component({
  selector: 'app-international-phone-input',
  templateUrl: './international-phone-input.component.html',
  styleUrls: ['./international-phone-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InternationalPhoneInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InternationalPhoneInputComponent),
      multi: true
    }
  ]
})
export class InternationalPhoneInputComponent implements ControlValueAccessor, OnInit {

  @Input() inputWidth: string = '100%';

  writeValue(value: string): void {
    if (value) {
      const cleanedValue = value.replace(/\s+/g, '');
      const phoneNumber = parsePhoneNumberFromString(cleanedValue);
      if (phoneNumber) {
        this.selectedCountry = phoneNumber.country;
        this.selectedCountryCode = phoneNumber.countryCallingCode
        this.phoneNumber = phoneNumber.nationalNumber || '';
      }
    }
    else{
      this.phoneNumber = ''
    }
  }
  setDisabledState?(isDisabled: boolean): void {
  }
  ngOnInit(): void { }

  onChange: any = () => {};
  onTouched: any = () => {};

  public invalidPhoneNumber : boolean

  selectedCountry = 'FR';
  selectedCountryCode: string = '33';
  phoneNumber = '';
  countries = getCountries().map(country => ({
    name: country,
    code: getCountryCallingCode(country)
  }));

  get internationalNumber() {
    return `+${this.countries.find(c => c.name === this.selectedCountry)?.code || ''}${this.phoneNumber}`;
  }

  onPhoneNumberChange(event : Event) {
    const inputElement = event.target as HTMLInputElement;
    this.phoneNumber = inputElement.value;
    this.notifyChange();
    
  }

  validate() {
    if (this.phoneNumber == ''){
      return null
    }
    const phoneNumber = parsePhoneNumberFromString(this.internationalNumber);
    return phoneNumber?.isValid() ? null : { invalidPhoneNumber: true };
  }

  onCountryChange(event: any): void {
    const selected = this.countries.find(country => country.name === event.value);
    if (selected) {
      this.selectedCountry = selected.name;
      this.selectedCountryCode = selected.code;
    }

    this.notifyChange();
  }

  notifyChange() {
    if (this.phoneNumber == ''){
      this.onChange('')
    }
    else{
      this.onChange(`+${this.selectedCountryCode} ${this.phoneNumber}`);
    }
   
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  getCountryFullName(countryISOCode : string): string | null{
    const country = countries.find(
      (country) => country.code.toUpperCase() === countryISOCode.toUpperCase()
    );
    
    return country ? country.name : null;
  }

}
