<header>
  <img [routerLink]="'/'" alt="Logo Hardis Group" src="assets/image/Logo_Hardis_Group_Blanc_1.svg" />
  <div class="right-part">
    <button
      *appAccessControl="[ACCESS_CONTROL.ADMIN_ORGANIZATIONS]"
      [routerLink]="'/admin-settings'"
      class="stroke-icon-button icon icon-settings-stroke"
      mat-button></button>

    <app-translation-switch></app-translation-switch>
    <app-user-profile></app-user-profile>
  </div>
</header>
