import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SearchDropdownOption } from '../shared/models/SearchDropdownOption';
import { Filter } from '../shared/models/filters/Filter';
import { FilterFactory } from '../shared/models/factories/FilterFactory';

interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-test-components',
  templateUrl: './test-components.component.html',
  styleUrls: ['./test-components.component.scss'],
})
export class TestComponentsComponent {
  filters: Filter[] = [
    FilterFactory.nameFilter().build(),
    FilterFactory.statusFilter().build(),
    FilterFactory.roleFilter().build(),
  ];
  foods: Food[] = [
    { value: 'steak-0', viewValue: 'Steak' },
    { value: 'pizza-1', viewValue: 'Pizza' },
    { value: 'tacos-2', viewValue: 'Tacos' },
  ];
  toppings = new FormControl(false);
  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];
  sdOptions: SearchDropdownOption[] = [
    { value: 1, label: 'Schneider Electric' },
    { value: 2, label: 'EDF' },
    {
      value: 3,
      label: 'ST Microelectronics',
    },
  ];
}
