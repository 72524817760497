import { Factory } from './Factory';
import { FilterOptions } from '../filters/FilterOptions';
import { FILTER_TYPE } from '../filters/FILTER_TYPE';
import { Filter } from '../filters/Filter';

export class FilterFactory implements Factory<Filter> {
  private label: string;
  private type: FILTER_TYPE;
  private columnTitle: string;
  private options?: FilterOptions;

  constructor(label: string, type: FILTER_TYPE, columnTitle: string, options?: FilterOptions) {
    this.label = label;
    this.type = type;
    this.columnTitle = columnTitle;
    this.options = options;
  }

  public static nameFilter(): FilterFactory {
    return new FilterFactory('Name', FILTER_TYPE.STRING, 'name', {
      alwaysActive: true,
      placeHolder: 'Search a name or an email',
    });
  }

  public static emailFilter(): FilterFactory {
    return new FilterFactory('Email', FILTER_TYPE.STRING, 'email');
  }

  public static lastSeenFilter(): FilterFactory {
    return new FilterFactory('Date', FILTER_TYPE.DATE_RANGE, 'date');
  }

  public static statusFilter(): FilterFactory {
    return new FilterFactory('Status', FILTER_TYPE.CHOICE, 'status', {
      alwaysActive: true,
      filterChoices: [
        { label: 'testStatus', value: '0' },
        { label: 'testStatus2', value: '1' },
      ],
    });
  }

  public static roleFilter(): FilterFactory {
    return new FilterFactory('Role', FILTER_TYPE.CHOICE, 'role', {
      filterChoices: [
        {
          label: 'testRole',
          value: '0',
        },
        { label: 'testRole2', value: '1' },
      ],
    });
  }

  build(): Filter {
    return new Filter(this.label, this.type, this.columnTitle, this.options);
  }
}
